<script setup>
import {reactive} from 'vue'

const props = defineProps({
  thisWeekSpResult: Array,
});
const isWeekOpen = reactive([]);

const handleThisToggle = (index) => {
  isWeekOpen[index] = !isWeekOpen[index];
}

const beforeEnter = (el) => {
  el.style.height = "0";
};

const enter = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const leave = (el) => {
  el.style.height = "0";
};
</script>

<template>
  <div class="inner">
    <h2 class="heading-circle">今週開催重賞実績</h2>
    <div v-if="!thisWeekSpResult.length">
      <ul>
        <li>今週開催重賞実績の情報はありません。</li>
      </ul>
    </div>
    <div v-else>
      <ul class="list-news">
        <li
          v-for="(result, index) in thisWeekSpResult"
          :key="index"
          data-accordion='["data-btn","data-detail"]'
          class="item -nopad"
        >
          <div class="feed">
            <div class="head">
              <div class="title">
                <div>
                  {{ result.RaceName}}
                  <i class="icon-race"
                     :class="{
                        '-jg1':
                          result.GradeCd === 'A' && result.TrackTypeCd === '3',
                        '-jg2':
                          result.GradeCd === 'B' && result.TrackTypeCd === '3',
                        '-jg3':
                          result.GradeCd === 'C' && result.TrackTypeCd === '3',
                        '-g1': result.GradeCd === 'A',
                        '-g2': result.GradeCd === 'B',
                        '-g3': result.GradeCd === 'C',
                        '-largeprize': result.GradeCd === 'D'
                      }">

                  </i>
                  <span v-if="result.Entry === 1" class="info">特別登録</span>
                  <span v-if="result.Entry === 2" class="info">出走確定</span>
                </div>
                <div class="sub">
                  {{ result.RaceCounter }}戦{{ result.WinCounter }}勝
                </div>
              </div>
              <div class="nav">
                  <span
                    class="icon-accordion"
                    :class="{ '-active': isWeekOpen[index] }"
                    @click="handleThisToggle(index)"
                    data-trigger
                  ></span>
              </div>
            </div>
            <transition
              name="topSlide"
              @before-enter="beforeEnter"
              @enter="enter"
              @before-leave="beforeLeave"
              @leave="leave"
            >
              <div
                v-if="isWeekOpen[index]"
                data-target
                class="topSlide detail"
                :class="{ '-active': isWeekOpen[index] }"
              >
                <div v-if="result.Detail === null"></div>
                <div v-else>
                  <table class="table-cell -center">
                    <tr v-for="(detail, index) in result.Detail" :key="index">
                      <td>{{ detail.YEAR }}年</td>
                      <td class="-left">
                        <i
                          :class="'icon-umaban -waku0' + detail.HorseStyle"
                        >{{ detail.HorseNum }}</i
                        >
                        {{ detail.HorseName }}
                      </td>
                      <td>{{ detail.WinPopularRank }}人気</td>
                      <td>
                          <span :class="detail.OddsClass"
                          >{{ detail.Odds }}倍</span
                          >
                      </td>
                      <td
                        :class="{
                            'bg-rank01': detail.Ranking === '1着',
                            'bg-rank02': detail.Ranking === '2着',
                            'bg-rank03': detail.Ranking === '3着',
                          }"
                      >
                        <a :href="detail.ResultUrl">{{ detail.Ranking }} </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </transition>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
