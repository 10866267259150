<template>
  <div class="item">
    <div class="inner">
      <h2 class="heading-circle">主な産駒</h2>
      <div id="graph-course"></div>
      <div v-if="offspringData.length">
        <table class="table-cell -padding">
          <tbody v-for="(offspring, key) in offspringData" :key="key">
          <tr>
            <th colspan="2" :class="offspring.SexClass">
              <div class="head">
                <div class="-left">
                  <a :href="offspring.RacehorseURL" class="link-icon">{{
                      offspring.HorseName
                    }}
                  </a>
                  <i :class="offspring.SnkDataFlg"></i>
                  <i :class="offspring.ErasureIcon"></i>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td rowspan="2">
              {{ offspring.YOB }}年生<br />{{ offspring.HairColorCd }}
            </td>
            <td>
              母：{{ offspring.MotherName }}　母の父：{{ offspring.MotherFatherName }}
            </td>
          </tr>
          <tr>
            <td>
              主な勝鞍：{{ offspring.RaceName }}
              <span :class="getRaceIcon(offspring.Grade, offspring.TrackTypeCd, offspring.RaceName, offspring.isSpRace)">
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        産駒情報はありません。
      </div>
    </div>
  </div>
</template>
<script>
import { isNar } from "../../../assets/js/common";

export default {
  props: {
    offspringData: Array,
  },
  methods: {
    getRaceIcon(grade, trackTypeCd, raceName, isSpRace) {
      let raceIcon
      if (isNar() === 1) {
        if (grade == "g1") {
          raceIcon = "icon-race -jpn1";
        } else if (grade == "g2") {
          raceIcon = "icon-race -jpn2";
        } else if (grade == "g3") {
          raceIcon = "icon-race -jpn3";
        }
      } else { // JRAの時
        if (grade == "g1") {
          if (trackTypeCd == "3") {
            raceIcon = "icon-race -jg1";
          } else {
            raceIcon = "icon-race -g1";
          }
        } else if (grade == "g2") {
          if (trackTypeCd == "3") {
            raceIcon = "icon-race -jg2";
          } else {
            raceIcon = "icon-race -g2";
          }
        } else if (grade == "g3") {
          if (trackTypeCd == "3") {
            raceIcon = "icon-race -jg3";
          } else {
            raceIcon = "icon-race -g3";
          }
        } else if (grade == "D") {
          raceIcon = "icon-race -largeprize";
        } else if (grade == "l") {
          raceIcon = "icon-race -L";
        } else if (raceName != undefined && raceName != "" && isSpRace) {
          raceIcon = "icon-race -op";
        }
      }
      return raceIcon
    }
  }
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
