<script setup>
import {ref, reactive, onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  getUserId,
  SUCCESS,
  PAYINGSUGOTOKU_NONE,
  isFavorite,
  isNextTarget,
  FRACEHORSE,
  TRACEHORSE,
  FJOCKEY,
  FTRAINER,
  PAYINGNONE,
  PAYINGECONOMY,
  PAYINGYAHOO,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  getUserAgent,
  isNar,
  getCourse,
  PAYINGSUGOTOKU,
  getGradeIconClassNar,
  getGradeIconClass,
} from "../../assets/js/common";
import {
  HOST,
  JRA_HOST,
  NAR_HOST,
  PATH,
  BACKWORDPATH,
  RACEINFOPATH,
  JOINPATH,
} from "../../assets/js/define";

import MyStampModal from "../../components/utils/MyStampModal.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";
import WeightModal from "../../components/utils/WeightModal.vue";
import myMark from "../../functions/odds/race-mymark";
import moment from "moment";
import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
import {fetchPredictedSalesBanner} from '../../../spa/assets/js/wordpress'

const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
} = storeToRefs(storeRaceCommon);

const { loadMyMarkForDrop2, setMyMark, setMyMarkForSpecial } = myMark();

let checkAllJudge = ref(false);
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "win";
let entryHorseNum = 0;
const existHorseNum = ref(true);
const pedigreeNums = ref([]);
const isKakutei = ref(true);

let mCourseVal = ref("");
const predictedSalesBanner = ref('');
let params = {};
let shouldShowMarks = ref(false);
let isLoaded = ref(false)
let isWebview = ref(false);

const modalOpen = ref(false);
const NARFLAG = ref(0);

const route = useRoute();

// 出馬表カスタマイズタイプ
const customizeCardType = ref('1');

watch(route, async (from, to) => {
  await doInit(to.query);
});

// time
let time = ref("");
onMounted(async () => {
  NARFLAG.value = isNar()
  params = getUrlVars();
  mCourseVal.value = getCourse();
  isWebview.value = getUserAgent();
  getMyNextTargetRacehorse();
  fetchPredictedSalesBanner(predictedSalesBanner, 'card');

  // 表示フラグ
  // 新聞印
  shouldShowMarks.value = NARFLAG.value === 0 &&
    [PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12, PAYINGSUGOTOKU].includes(mCourseVal.value)

  //パラメータチェック
  if (checkUrlVars(params)) {
    await doInit(params);
    isLoaded.value = true;
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
    document.body.className = "status-loaded";
  }
});

const getMyNextTargetRacehorse = () => {
  let userId = getUserId();
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId)
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if(json){
        pedigreeNums.value = json;
      }
    }
  });
}

const changeImg = (h) => {
  h.JockeyImg = "";
};

let stamp = reactive({});
const stampClass = (pedigreeNum) => {
  if (stamp[pedigreeNum].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[pedigreeNum].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[pedigreeNum].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[pedigreeNum].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[pedigreeNum].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[pedigreeNum].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else {
    return "stamp-mark -none";
  }
};

const oddsClass = (val) => {
  if (val == "---.-" || val === '0.0') {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};

//チェックリスト関連
let checkList = reactive({});
let checkedSum = ref(0);
const checkChange = (num) => {
  if (checkList[num] === true) {
    checkList[num] = false;
  } else {
    checkList[num] = true;
  }
  checkedHorseSum();
};
const checkedHorseSum = () => {
  let checkedNum = 0;
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      checkedNum++;
    }
  }

  checkedSum.value = checkedNum;
};
const checkedAll = () => {
  if (checkAllJudge == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
    }
    checkAllJudge = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3") {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
      }
    }
    checkAllJudge = true;
  }
  checkedHorseSum();
};

// modal
let left = ref("");
let top = ref("");
const openModal = (event, pedigreeNum) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[pedigreeNum].status = true;
};
const closeStampModal = (win, pedigreeNum) => {
  document.body.className = "status-loaded";
  const horse = horses.find(val => val.PedigreeNum === pedigreeNum)
  if ( Number.isNaN(stamp[pedigreeNum].selectedNum) || Number.isNaN(win.selectedNum) ||
      stamp[pedigreeNum].selectedNum === win.selectedNum || !horse ) {
    stamp[pedigreeNum].status = false;
    return;
  }
  horse.SelectedNum = win.selectedNum;
  //my印を新しくセットした場合は、my印を更新する
  stamp[pedigreeNum].selectedNum = win.selectedNum;
  if (existHorseNum.value) {
    setMyMark(
        getUserId(),
        raceDORVal.value,
        raceTrackCdVal.value,
        raceNumVal.value,
        horse.HorseNum,
        stamp[pedigreeNum].selectedNum
    );
  } else {
    setMyMarkForSpecial(
        getUserId(),
        raceDORVal.value,
        raceTrackCdVal.value,
        raceNumVal.value,
        pedigreeNum,
        stamp[pedigreeNum].selectedNum
    );
  }
  stamp[pedigreeNum].status = false;
};

let targetWeightData = reactive({});

const weightModal = (
  pedigreeNum,
  dor,
  horseWeight,
  horseName,
  weightArray,
  weightDifference
) => {
  //会員情報を取得し、ページ遷移
  if (mCourseVal.value === PAYINGNONE) {
    location.href = HOST + JOINPATH;
  } else if (mCourseVal.value === PAYINGYAHOO) {
    location.href = "y_premium/bridge";
  } else if (mCourseVal.value === PAYINGSUGOTOKU_NONE) {
    location.href = HOST + "joinSugotoku.js";
  } else {
    let horse_weight = "";
    if (horseWeight) {
      if (!weightDifference) {
        horse_weight = horseWeight
      } else if (weightDifference > 0) {
        horse_weight = horseWeight + "(+" + weightDifference + ")";
      } else {
        horse_weight = horseWeight + "(" + weightDifference + ")";
      }
    }
    let weightData = {
      pedigreeNum: pedigreeNum,
      dor: dor,
      horseWeight: horseWeight,
      horseName: horseName,
      weightArray: weightArray,
      horse_weight: horse_weight,
    };
    Object.assign(targetWeightData, weightData);
    //test.value = { dor: dor, horseWeight: horseWeight };
    modalOpen.value = true;
  }
};

// horses
let horses = reactive([]);
let newsPaperMarks = reactive([]);
let marks = reactive({});
const hasNotice = ref(false);
const doInit = async (params) => {
  document.body.className = "";
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  await loadNewspaperMarkSummary(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  await loadCandidacyTableForOddsBasic(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  )
  await Promise.all([
    loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum),
    getCustomizeCardType()
  ]);
  document.body.className = "status-loaded";
};

const loadNewspaperMarkSummary = async (dor, racetrackCd, raceNum) => {
  if (isNar() === 0) {
    //パラメータからリクエストURL作成
    const request =
        HOST +
        PATH +
        "getNewspaperMarkSummary.js" +
        BACKWORDPATH +
        "?dor=" +
        dor +
        "&racetrackCd=" +
        racetrackCd +
        "&raceNum=" +
        raceNum +
        "&narFlag=" +
        isNar();
    await getJSON(request, true, function (status, json) {
      if (status == SUCCESS) {
        if (json && ("EntryLst" in json) && json.EntryLst.length) {
          newsPaperMarks = json.EntryLst
        } else {
          newsPaperMarks = []
        }
      }
    });
  }
  return 0;
};

//レース詳細情報を取得する
const loadCandidacyTableForOddsBasic = async (
  dor,
  racetrackCd,
  raceNum,
  callback
) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == "success") {
      entryHorseNum = json.EntryNum;
      hasNotice.value = json.AddNotice;
      horses.splice(0, horses.length);
      horses = Object.assign(horses, json["EntryHorses"]);
      let useData;
      let spEntryFlag;
      let saveCandidacyData;

      saveCandidacyData = horses;

      let newspaperMarkRankRows = [];
      if (isNar() === 0) {
        // 新聞印の色付け
        const createNewspaperMarkRankRows = (rows) => {
          const res = []
          let nowCount = null;
          let tmpRank = 1;
          let duplicationCount = 0;

          horses.forEach(function (value, index) {
            if (!rows.some(val => val.PedigreeNum === value.PedigreeNum)) {
              rows.push({
                "PedigreeNum": value.PedigreeNum,
                "HorseName": value.HorseName,
                "BracketNum": value.BracketNum,
                "HorseNum": Number(value.HorseNum),
                "FavNum1": 0,
                "FavNum2": 0,
                "FavNum3": 0,
                "FavNum4": 0,
                "FavNum5": 0
              })
            }
          })

          const newspaperMarkRank = rows.sort((a, b) => {
            if (a.FavNum1 < b.FavNum1) return 1;
            if (a.FavNum1 > b.FavNum1) return -1;
            return 0;
          });

          newspaperMarkRank.forEach(function (data) {
            if (Number(data.FavNum1) === 0) {
              res.push({
                HorseNum: data.HorseNum,
                rank: 99
              })
            } else if (nowCount === null) {
              res.push({
                HorseNum: data.HorseNum,
                rank: tmpRank
              })
              nowCount = data.FavNum1
            } else if (nowCount === data.FavNum1) {
              res.push({
                HorseNum: data.HorseNum,
                rank: tmpRank
              })
              duplicationCount++
            } else if (res.length <= 2) {
              tmpRank = tmpRank + duplicationCount + 1
              duplicationCount = 0
              // 3位以上は必要無い
              if (tmpRank <= 3) {
                res.push({
                  HorseNum: data.HorseNum,
                  rank: tmpRank
                })
                nowCount = data.FavNum1
              }
            }
          })

          return res;
        }

        // 新聞印の色付け値格納
        newspaperMarkRankRows = createNewspaperMarkRankRows(newsPaperMarks)
      }

      //my印及びチェックボックスをデフォルト値をセット

      for (let i = 0; i < horses.length; i++) {
        useData = horses[i];
        let bracketNum = "";

        useData.DoubleCircleCount = newsPaperMarks.length && newsPaperMarks.some(val => val.PedigreeNum === useData.PedigreeNum) ?
          newsPaperMarks.find(val => val.PedigreeNum === useData.PedigreeNum).FavNum1 : 0

        if (useData.BracketNum != null && useData.BracketNum !== "") {
          spEntryFlag++;
          bracketNum = useData.BracketNum;
        }

        let frame_num_class = "";
        let horse_num_class = "";
        if ("BracketNum" in useData && useData.BracketNum != null) {
          let frame = useData.BracketNum;
          frame_num_class = "wk" + frame;
          horse_num_class = "um" + frame;
        }

        //馬名
        let horseNameUrl = "";
        let favClass = "";
        let nextTargetClass = "";
        let oddsLink = "";

        if (useData.PedigreeNum) {
          // ばんえいは対応しない
          if (racetrackCd !== '33') {
            horseNameUrl +=
              HOST + "data/horse?PedigreeNum=" + useData.PedigreeNum;
            if (isFavorite(FRACEHORSE, useData.PedigreeNum)) {
              favClass = "-favorite";
            }
            if ((isNextTarget(TRACEHORSE, useData.PedigreeNum) && pedigreeNums.value.includes(useData.PedigreeNum))) {
              nextTargetClass = "-nextrun";
            }
          }

          oddsLink =
            HOST +
            "horse_odds?DOR=" +
            dor +
            "&Waku=" +
            useData.BracketNum +
            "&RacetrackCd=" +
            racetrackCd +
            "&RaceNum=" +
            raceNum +
            "&HorseNum=" +
            useData.HorseNum +
            "&PedigreeNum=" +
            useData.PedigreeNum +
            "&EntryNum=" +
            entryHorseNum +
            "&HorseName=" +
            useData.HorseName;
        }
        useData.HorseNameUrl = horseNameUrl;
        useData.FavClass = favClass;
        useData.NextTargetClass = nextTargetClass;
        useData.Grandfather = useData.Grandfather ? "（" + useData.Grandfather + "）" : ""

        // 馬オッズへのリンク
        useData.OddsLink = oddsLink;

        //性別・馬齢・毛色
        let horse_detail = "";
        if (useData.Sex != undefined) horse_detail += useData.Sex;
        if (useData.Age != undefined && useData.Age != 0)
          horse_detail += useData.Age;

        if (useData.HairColorCd) {
          horse_detail += " ";
          //毛色はスペースの関係から2文字表示
          horse_detail += useData.HairColorCd.slice(0, 2);
        }
        useData.HairColorCd = horse_detail;

        //騎手
        let jockeyName = "";
        let jockeyNameUrl = "";
        if (useData.JockeyName != undefined) {
          jockeyName = useData.JockeyName;
          //名前が5文字以上の場合 ※スペースを含む6文字以上
          if (jockeyName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
            //C.デムーロ、M.デムーロだけ「ファーストネームのアルファベット + ．+ 全角カナ2文字」
            if (jockeyName.trim() == "Ｃ．デムーロ") {
              jockeyName = "Ｃ．デム";
            } else if (jockeyName.trim() == "Ｍ．デムーロ") {
              jockeyName = "Ｍ．デム";
            } else if (jockeyName.trim() == "Ｔ．ベリー") {
              jockeyName = "Ｔ．ベリ";
            } else if (jockeyName.trim() == "Ｆ．ベリー") {
              jockeyName = "Ｆ．ベリ";
            } else {
              //他の外国騎手は、カタカナ表記の頭4文字
              let newJockeyName = jockeyName.substr(2, jockeyName.length - 2);
              if (newJockeyName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
                newJockeyName = newJockeyName.substr(
                  2,
                  newJockeyName.length - 2
                );
              }
              jockeyName = newJockeyName.substr(0, 4);
            }
          }
        }
        jockeyNameUrl += HOST + "data/jockey?JockeyCd=" + useData.JockeyCd;
        // 騎手名を6文字目からは表示させない
        useData.JockeyName = jockeyName.substr(0, 6);
        useData.JockeyNameUrl = jockeyNameUrl;

        if (useData.EastWestDiv === "東" || useData.EastWestDiv === "西") {
          useData.JockeyImg = "";
        } else {
          useData.JockeyImg = useData.JockeyCd ?
            "../../assets/images/data/images/jockey/" +
            useData.JockeyCd.slice(1, 6) +
            ".png" : '';
        }

        // レース間隔 前走レース（取消、除外は除く）の日付からレース間隔を計算する
        const dateFrom = moment(dor.slice(0, 4) + "-" + dor.slice(4, 6) + "-" + dor.slice(6, 8))
        const accidentCdArray = ['1', '2', '3']
        const latestRace = useData.WeightArray.find(val => !accidentCdArray.includes(val.AccidentCd))
        if (latestRace) {
          let dateTo = latestRace.DOR;
          dateTo = moment(dateTo.slice(0, 4) + "-" + dateTo.slice(4, 6) + "-" + dateTo.slice(6, 8))
          let diffDays = dateFrom.diff(dateTo, 'days');
          diffDays = Math.floor((diffDays - 3) / 7)

          useData.Interval = diffDays === 0 ?
            '連闘' :
            "中" + diffDays + "週";
        }

        //jockeyCdが存在する場合にお気に入りの騎手を取得
        useData.FavClassJockey = useData.JockeyCd ? isFavorite(FJOCKEY, useData.JockeyCd) : 0;

        let weight = "";
        if (useData.Weight != undefined) {
          weight = (useData.Weight / 10).toFixed(1);
        }
        useData.Weight = weight;

        //調教師設定
        //調教師所属表示
        let eastWest = "";
        if (
          useData.EastWestDiv != undefined &&
          useData.EastWestDiv != null &&
          useData.EastWestDiv != ""
        ) {
          eastWest = "[" + useData.EastWestDiv + "]";
        } else if (!useData.PedigreeNum) {
          eastWest = "";
        } else if (
          useData.EastWestDiv == "" &&
          useData.TrainerName != undefined &&
          useData.TrainerName != null
        ) {
          if (useData.TrainerName.substr(0, 1).match(/[Ａ-ＺA-Z]/i)) {
            eastWest = "[外]";
          } else {
            eastWest = "[地]";
          }
        }
        useData.EastWestDiv = eastWest;

        //調教師名表示
        let trainerName = "";
        if (useData.TrainerName != undefined) {
          trainerName = useData.TrainerName;
          if (trainerName.length >= 6) {
            //名前が5文字以上の場合
            if (trainerName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
              //他の外国騎手は、カタカナ表記の頭4文字
              let newTrainerName = trainerName.substr(
                2,
                trainerName.length - 2
              );
              if (newTrainerName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
                newTrainerName = newTrainerName.substr(
                  2,
                  newTrainerName.length - 2
                );
              }
              trainerName = newTrainerName.substr(0, 4);
            }
          }
        }
        useData.TrainerName = trainerName;

        //調教師リンク,お気に入り設定
        useData.FavClassTrainer = useData.TrainerCd ? isFavorite(FTRAINER, useData.TrainerCd) : 0;
        useData.TrainerNameUrl = "/data/trainer?TrainerCd=" + useData.TrainerCd;

        //脚質を設定
        const max = Math.max(
          useData.TypeNige,
          useData.TypeSenko,
          useData.TypeSasi,
          useData.Typeoiko
        );
        const runStyle = {
          isNige: useData.TypeNige !== 0 && useData.TypeNige === max,
          isSenko: useData.TypeSenko !== 0 && useData.TypeSenko === max,
          isSasi: useData.TypeSasi !== 0 && useData.TypeSasi === max,
          isOiko: useData.Typeoiko !== 0 && useData.Typeoiko === max,
        };
        const runStyleCount = Object.values(runStyle).reduce(
          (count, flag) => (count += flag ? 1 : 0)
        );
        useData.isMulti = runStyleCount > 1;
        useData.RunStyle = runStyle;

        //馬体重
        let horse_weight = "";
        if (useData.HorseWeight != undefined && useData.HorseWeight > 0) {
          //登録されていないか、出走取消（=000）の場合
          if (useData.HorseWeight == 999 && racetrackCd !== "33") {
            //今走計量不能（=999）の場合
            horse_weight += "計不";
          } else {
            horse_weight += useData.HorseWeight;
          }
        }
        let horse_weight_class = "";
        let weight_difference_class = "";
        let weight_difference = "";
        if (useData.WeightDifference != undefined) {
          if (
            racetrackCd === "33" ||
            (useData.WeightDifference < 999 &&
              useData.HorseWeight < 999 &&
              useData.HorseWeight > 0 &&
              useData.HorseWeight !== useData.WeightDifference)
          ) {
            weight_difference = useData.WeightDifference
            if (useData.WeightDifference > 9) {
              horse_weight_class += "color:#ff0808; font-weight: bold;";
              weight_difference_class += "+";
            } else if (useData.WeightDifference > 0) {
              horse_weight_class += "color:#ff0808;";
              weight_difference_class += "+";
            } else if (useData.WeightDifference < -9) {
              horse_weight_class += "color:#404dd6; font-weight: bold;";
            } else if (useData.WeightDifference < -0) {
              horse_weight_class += "color:#404dd6;";
            } else if (Number(useData.WeightDifference) === 0) {
              horse_weight_class += "0";
            } else {
              horse_weight_class += "";
            }
          }
        }
        useData.WeightDifference = weight_difference;
        useData.HorseWeightClass = horse_weight_class;
        useData.WeightDifferenceClass = weight_difference_class;

        let hideClass = "";
        if (useData.HorseNum) {
          useData.ExistHorseNum = true;
          //競走除外、出走取消用のクラス追加
          if ("AbnormalDiv" in useData) {
            if ("1" === useData.AbnormalDiv) {
              //出走取消の場合
              hideClass = " table-excluded";
            } else if ("3" === useData.AbnormalDiv) {
              //競走除外の場合
              hideClass = " table-canceled";
            }
          }
        } else {
          useData.HorseNum = i + 1
          useData.ExistHorseNum = false;
          existHorseNum.value = false
        }
        isKakutei.value = useData.IsKakutei

        //My印をセット
        let selectNum = 0;
        if (marks && Object.keys(marks).length) {
          if (marks.isSpFlg === '1') {
            selectNum = marks.SpecialMarks[useData.PedigreeNum] || 0;
          } else {
            selectNum = marks["MyNo" + (i + 1)] || 0;
          }
        }

        stamp[useData.PedigreeNum] = {status: false, selectedNum: selectNum};
        checkList[i] = false;
        useData.SelectedNum = stamp[useData.PedigreeNum].selectedNum;

        if (isNar() === 0) {
          // 新聞印のクラスを追加していく
          let newspaperMarkRank = newspaperMarkRankRows.find((item) => {
            return item.HorseNum == useData.HorseNum
          })

          if (newspaperMarkRank !== undefined) {
            switch (newspaperMarkRank.rank) {
              case 1:
                useData.newspaperMarkBgClass = 'bg-rank01'
                break;
              case 2:
                useData.newspaperMarkBgClass = 'bg-rank02'
                break;
              case 3:
                useData.newspaperMarkBgClass = 'bg-rank03'
                break;
              default:
                break;
            }
          }
        }
      }
      // 優先順を表示するときはSpecialRaceOrderの昇順にソート
      if (!isKakutei.value && ![PAYINGNONE, PAYINGECONOMY].includes(mCourseVal.value)) {
        horses.sort((a, b) => {
          if (a.VoteRank !== b.VoteRank) {
            return a.VoteRank - b.VoteRank;
          }
          return a.SpecialRaceOrder - b.SpecialRaceOrder;
        })
      }
      let pedigreeNumArray = [];
      for (let i = 0; i < horses.length; i++) {
        let horseData = horses[i];
        if (horseData.PedigreeNum) {
          pedigreeNumArray.push(horseData.PedigreeNum);
        }
      }

      if (pedigreeNumArray.length) {
        let entryArray = new Array();
        //パラメータからAPIコールを生成
        let req =
          HOST +
          RACEINFOPATH +
          "raceLastRunsAll.js" +
          BACKWORDPATH +
          "?dor=" +
          dor +
          "&pedigreeNum=" +
          pedigreeNumArray +
          "&narFlag=" +
          isNar();
        getJSON(req, false, function (status, jsonLastRunAll) {
          if (status == SUCCESS) {
            if (jsonLastRunAll.length > 0) {
              for (let i = 0; i < saveCandidacyData.length; i++) {
                entryArray.push({
                  PedigreeNum: saveCandidacyData[i].PedigreeNum,
                  BreederCd: "",
                  BreederName: "",
                  HorseOwnerCd: "",
                  HorseOwnerName: "",
                  AuctionInfo: "",
                  RaceEntry: [],
                });
                for (let j = 0; j < jsonLastRunAll.length; j++) {
                  if (
                    saveCandidacyData[i].PedigreeNum ===
                    jsonLastRunAll[j].PedigreeNum
                  ) {
                    entryArray[i].BreederCd = jsonLastRunAll[j].BreederCd;
                    entryArray[i].BreederName = jsonLastRunAll[j].BreederName;
                    entryArray[i].HorseOwnerCd = jsonLastRunAll[j].HorseOwnerCd;
                    entryArray[i].HorseOwnerName =
                      jsonLastRunAll[j].HorseOwnerName;
                    entryArray[i].AuctionInfo = jsonLastRunAll[j].AuctionInfo;

                    let raceResult = jsonLastRunAll[j].RaceResult; // raceResultは1馬の過去分
                    entryArray[i].JockeyRiding = jsonLastRunAll[j].JockeyRiding
                    for (let k = 0; k < raceResult.length && k < 5; k++) {
                      // 海外開催のレースはリンク不要
                      raceResult[k].RaceURL = ''
                      if (Number(raceResult[k].RacetrackCd) <= 10) {
                        raceResult[k].RaceURL =
                          JRA_HOST +
                          "race/results?DOR=" +
                          raceResult[k].DOR +
                          "&RacetrackCd=" +
                          raceResult[k].RacetrackCd +
                          "&RaceNum=" +
                          raceResult[k].RaceNum;
                      } else if (
                        Number(raceResult[k].RacetrackCd) >= 30 &&
                        Number(raceResult[k].RacetrackCd) <= 57
                      ) {
                        raceResult[k].RaceURL =
                          NAR_HOST +
                          "race/results?DOR=" +
                          raceResult[k].DOR +
                          "&RacetrackCd=" +
                          raceResult[k].RacetrackCd +
                          "&RaceNum=" +
                          raceResult[k].RaceNum;
                      }

                      //着順と馬場状態による表示
                      let rankClass = "";
                      if (raceResult[k].TrackCondition == "良") {
                        rankClass = "rank -ryo";
                      } else if (raceResult[k].TrackCondition == "不良") {
                        rankClass = "rank -huryo";
                      } else if (raceResult[k].TrackCondition == "稍重") {
                        rankClass = "rank -yayaomo";
                      } else if (raceResult[k].TrackCondition == "重") {
                        rankClass = "rank -omo";
                      }
                      raceResult[k].RankClass = rankClass;

                      //　着順が上位3着までbackground-colorの付与
                      raceResult[k].BgClass = raceResult[k].RacePlace === 1 ? "run bg-rank01"
                        : raceResult[k].RacePlace === 2 ? "run bg-rank02"
                          : raceResult[k].RacePlace === 3 ? "run bg-rank03"
                            : "run"

                      //レースの種別の判定
                      let gradeIcon = "";
                      if (Number(raceResult[k].RacetrackCd) >= 30 && Number(raceResult[k].RacetrackCd) <= 57) {
                        // NAR競走場
                        gradeIcon = getGradeIconClassNar(
                          raceResult[k].GradeCd,
                          raceResult[k].RaceTitle,
                          raceResult[k].RaceRank
                        )
                      } else {
                        // JRA競走場
                        if (raceResult[k].IsSpRace) {
                          gradeIcon = getGradeIconClass(
                            raceResult[k].GradeCd,
                            raceResult[k].TrackTypeCd,
                            raceResult[k].RaceRegulationCd
                          )
                        }
                      }
                      raceResult[k].GradeIcon = gradeIcon;

                      let raceIcon = ""
                      if (!gradeIcon) {
                        if (raceResult[k].RaceTitle.match(/新馬/)) {
                          raceIcon = '<span class="icon-race -nograde">新馬</span>';
                        } else if (raceResult[k].RaceTitle.match(/未勝利/) && raceResult[k].Track !== "障") {
                          raceIcon = '<span class="icon-race -nograde">未勝利</span>';
                        } else if (raceResult[k].RaceTitle.match(/500万円以下/)) {
                          raceIcon = '<i class="icon-race -p1"></i>';
                        } else if (raceResult[k].RaceTitle.match(/1000万円以下/)) {
                          raceIcon = '<i class="icon-race -p2"></i>';
                        } else if (raceResult[k].RaceTitle.match(/1600万円以下/)) {
                          raceIcon = '<i class="icon-race -p3"></i>';
                        } else if (raceResult[k].RaceTitle.match(/未勝利/) && raceResult[k].Track === "障") {
                          raceIcon = '<span class="icon-race -nograde">障未</span>';
                        } else if (raceResult[k].RaceTitle.match(/オープン/) && raceResult[k].Track === "障") {
                          raceIcon = '<span class="icon-race -nograde">障OP</span>';
                        }
                      }
                      raceResult[k].RaceIcon = raceIcon;

                      //JRA開催場の場合オッズ表示
                      if (
                        raceResult[k].RacetrackCd.substr(0, 1) === "0" ||
                        raceResult[k].RacetrackCd === "10"
                      ) {
                        let req =
                          HOST +
                          PATH +
                          "getOldWinTicketOdds.js" +
                          BACKWORDPATH +
                          "?RacetrackCd=" +
                          raceResult[k].RacetrackCd +
                          "&RaceYear=" +
                          raceResult[k].DOR.substr(2, 2) +
                          "&RaceMeeting=" +
                          raceResult[k].RaceMeeting +
                          "&RaceDay=" +
                          raceResult[k].RaceDay +
                          "&RaceNum=" +
                          raceResult[k].RaceNum +
                          "&HorseNum=" +
                          raceResult[k].HorseNum +
                          "&DOR=" +
                          raceResult[k].DOR +
                          "&NarFlag=0";
                        getJSON(
                          req,
                          false,
                          function (oldOddsstatus, oldOddsjsonLastRun) {
                            if (oldOddsstatus == SUCCESS) {
                              if (
                                oldOddsjsonLastRun !== undefined &&
                                oldOddsjsonLastRun !== null &&
                                oldOddsjsonLastRun !== ""
                              ) {
                                if (
                                  String(oldOddsjsonLastRun).indexOf(".") === -1
                                ) {
                                  raceResult[k].odds = parseInt(
                                    oldOddsjsonLastRun,
                                    10
                                  ).toFixed(1);
                                }
                                raceResult[k].odds = oldOddsjsonLastRun;
                              } else {
                                raceResult[k].odds = "";
                              }
                            } else {
                              raceResult[k].odds = "---.- ";
                            }
                          }
                        );
                      }

                      //タイムの整形
                      let raceTime = '';
                      if (
                        raceResult[k].PrizeRaceTime !== undefined &&
                        raceResult[k].PrizeRaceTime !== null &&
                        Number(raceResult[k].PrizeRaceTime) > 0
                      ) {
                        let time = raceResult[k].PrizeRaceTime;
                        if (time.length == 5) {
                          let oneDecimal = time.substr(4, 1);
                          if (oneDecimal.length == 0) {
                            oneDecimal = 0;
                          }
                          raceTime = parseInt(time.substr(0, 2), 10) + ":";
                          raceTime += time.substr(2, 2) + ".";
                          raceTime += oneDecimal;
                        } else if (time.length == 4) {
                          raceTime = time.substr(0, 1) + ":";
                          raceTime += time.substr(1, 2) + ".";
                          raceTime += time.substr(3, 1);
                        }
                      }
                      raceResult[k].PrizeRaceTime = raceTime;

                      // 後半３F
                      let furlongTime = "";
                      let furlongClass = "";
                      if (
                        raceResult[k].FurlongTime !== undefined &&
                        raceResult[k].FurlongTime !== null &&
                        raceResult[k].FurlongTime !== ''
                      ) {
                        if (raceResult[k].FurlongTime != "9999") {
                          //取消・除外・中止(=9999)の場合、表示しない
                          if (raceResult[k].FurlongFlg == 1) {
                            furlongClass = "-no1";
                          } else if (raceResult[k].FurlongFlg == 2) {
                            furlongClass = "-no2";
                          } else if (raceResult[k].FurlongFlg == 3) {
                            furlongClass = "-no3";
                          }
                          furlongTime = raceResult[k].FurlongTime;
                          furlongTime = String(parseInt(furlongTime, 10) / 10);
                          if (furlongTime.indexOf(".") == -1) {
                            furlongTime = furlongTime + ".0";
                          }

                          if (furlongTime == "(0.0)") {
                            furlongTime = "";
                          }
                        }
                        raceResult[k].FurlongTime = furlongTime;
                        raceResult[k].FurlongClass = furlongClass;
                      }

                      // 確定１着・２着馬とのタイム差
                      let timeGapWithTop2 = '';
                      if (
                        raceTime !== '' &&
                        raceResult[k].TimeGapWithTop2 !== undefined &&
                        raceResult[k].TimeGapWithTop2 !== null
                      ) {
                        let timeGap = raceResult[k].TimeGapWithTop2;
                        if (
                          (String(timeGap).indexOf(".") == -1 && timeGap.length == 3) ||
                          (String(timeGap).indexOf(".") == -1 && timeGap.length == 4)
                        ) {
                          timeGap = timeGap * 1;
                          if (timeGap == 0) {
                            timeGap = "0.0";
                          } else {
                            if (timeGap > 0 || 0 > timeGap) {
                              timeGap = timeGap / 10;
                              if ("RacePlace" in raceResult[k] && raceResult[k].RacePlace != null) {
                                if (
                                  raceResult[k].RacePlace * 1 == 1 &&
                                  String(timeGap).substr(0, 1) != "-"
                                ) {
                                  timeGap = "-" + timeGap;
                                } else if (
                                  raceResult[k].RacePlace * 1 != 1 &&
                                  String(timeGap).substr(0, 1) == "-"
                                ) {
                                  timeGap = String(timeGap).substr(
                                    1,
                                    String(timeGap).length - 1
                                  );
                                }
                              }
                              if (String(timeGap).indexOf(".") == -1) {
                                timeGap += ".0";
                              }
                            }
                          }
                        } else if (timeGap == "999.9") {
                          timeGap = "";
                        } else if (100 <= timeGap * 1) {
                          let tmp_min = Math.floor(timeGap / 100);
                          let tmp_sec = tmp_min * 60;
                          timeGap = timeGap - tmp_min * 100 + tmp_sec;
                        } else if (timeGap != "" && String(timeGap).indexOf(".") == -1) {
                          timeGap = timeGap + ".0";
                        }

                        if (timeGap == "") {
                          timeGapWithTop2 = "";
                        } else if (timeGap != "999.9") {
                          if (timeGap > 0) {
                            timeGap = '+' + timeGap
                          }
                          timeGapWithTop2 = timeGap;
                        }
                      }
                      raceResult[k].TimeGapWithTop2 = timeGapWithTop2

                      //騎手体重
                      raceResult[k].BurdenWeight = (
                        raceResult[k].BurdenWeight / 10
                      ).toFixed(1);

                      // 馬体重
                      let horse_weight = "";
                      if (
                        raceResult[k].Weight != undefined &&
                        raceResult[k].Weight != 0
                      ) {
                        //登録されていないか、出走取消（=000）の場合
                        if (racetrackCd === "33") {
                          horse_weight += String(raceResult[k].Weight);
                        } else if (raceResult[k].Weight == 999) {
                          //今走計量不能（=999）の場合
                          horse_weight += "計不";
                        } else if (raceResult[k].Weight == 998) {
                          //計量不能（=998）の場合
                          horse_weight += "";
                        } else {
                          horse_weight += String(raceResult[k].Weight);
                        }
                      }
                      const weightDifference = raceResult[k].WeightDifference
                      if (typeof weightDifference === "number") {
                        if (weightDifference < 999) {
                          //前走計測不能時（=999）か初出走、出走取消（=0）以外のみ表示
                          const plusMark = weightDifference > 0 ? '+' : '';
                          horse_weight = `${horse_weight}(${plusMark}${weightDifference})`
                        }
                      }
                      raceResult[k].Weight = horse_weight;

                      if (raceResult[k].TrackRotationDiv == "1") {
                        raceResult[k].TrackRotation = "左";
                      } else if (raceResult[k].TrackRotationDiv == "2") {
                        raceResult[k].TrackRotation = "右";
                      } else if (raceResult[k].TrackRotationDiv == "3") {
                        raceResult[k].TrackRotation = "直線";
                      }

                      //ペース（中止、除外、取消時に非表示）
                      if (raceResult[k].AccidentCd === '1' || raceResult[k].AccidentCd === '2' ||
                        raceResult[k].AccidentCd === '3' || raceResult[k].AccidentCd === '4') {
                        raceResult[k].Pace = null
                      }

                      // レースの休養の判定
                      const currentDOR = raceResult[k].DOR
                      const nextDOR = k === 0 ? dor : raceResult[k - 1].DOR;
                      const currentRaceDay = new Date(
                        currentDOR.slice(0, 4) + "/" +
                        currentDOR.slice(4, 6) + "/" +
                        currentDOR.slice(6, 8)
                      );
                      const nextRaceDay = new Date(
                        nextDOR.slice(0, 4) + "/" +
                        nextDOR.slice(4, 6) + "/" +
                        nextDOR.slice(6, 8)
                      );
                      const intervalMonth = Math.floor(
                        (nextRaceDay - currentRaceDay) /
                        1000 /
                        60 /
                        60 /
                        24 /
                        30
                      );

                      if (intervalMonth >= 3) {
                        entryArray[i].RaceEntry.push({
                          IntervalMonth: intervalMonth,
                        });
                        if (entryArray[i].RaceEntry.length === 5) {
                          break;
                        }
                      }

                      entryArray[i].RaceEntry.push(raceResult[k]);
                      if (entryArray[i].RaceEntry.length === 5) {
                        break;
                      }
                    }
                  }
                }
                horses[i].RaceResultData = entryArray[i];
              }
            }
          }
        });
      }
    }
    if (callback !== undefined) {
      callback();
    }
  });

  return 0;
};

//オッズを取得する
let oddsWin = reactive([]);
const loadOddsWin = async (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  let req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];
        if (displayData["OddsUpdInfo"] != null)
          oddsUpdInfo.value = displayData["OddsUpdInfo"];

        // オッズを格納
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = (mCourseVal.value === PAYINGNONE  && !isWebview.value) && displayData["Odds" + (horses[i].HorseNum)] ?
              "---.-" : displayData["Odds" + (horses[i].HorseNum)];
        }
        // 人気表示
        loadOddsPopularWin(json[0])
      } else {
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = '0.0';
        }
      }
    }
  });

  return 0;
};

//人気表示
let popularOdds = reactive([]);
// TODO: race-odds.jsの同関数と共通化
const loadOddsPopularWin = (displayData) => {
  //新しいjsonを作る
  let oddsObj = new Array();
  for (var i = 1; i <= entryHorseNum; i++) {
    var id = "Odds" + i;
    displayData[id] =
      displayData[id] === "---.-" || displayData[id] === "0.0" ? 9999 : displayData[id];
    let obj = {name: id, Odds: parseFloat(displayData[id])};
    oddsObj.push(obj);
  }
  //オッズでソートする
  let sort_by = function (field, reverse, primer) {
    reverse = reverse ? -1 : 1;
    return function (a, b) {
      a = a[field] * 10;
      b = b[field] * 10;
      if (typeof primer != "undefined") {
        a = primer(a);
        b = primer(b);
      }
      if (a < b) return reverse * -1;
      if (a > b) return reverse * 1;
    };
  };
  oddsObj.sort(sort_by("Odds", false, parseInt));
  //人気表示
  let preOdds = 0.0;
  let prePopular = 1;
  let popular = 1;
  let popularNumber = [];
  for (let i = 0; i < oddsObj.length; i++) {
    let useOdds = oddsObj[i];
    //前のオッズと同じだった場合、同人気
    let targetNumber = 0
    if (i > 0 && oddsObj[i].Odds === preOdds) {
      targetNumber = prePopular;
    } else {
      targetNumber = popular;
      prePopular = popular
    }
    preOdds = oddsObj[i].Odds;
    popular++
    let num = useOdds.name.split("Odds")[1];
    popularNumber.push({
      horseNum: Number(num),
      popular: targetNumber
    });
  }

  //列順にスタックする
  popularOdds.splice(0, popularOdds.length);
  for (let n = 0; n < popularNumber.length; n++) {
    if ((mCourseVal.value === PAYINGNONE && !isWebview.value) || mCourseVal.value === PAYINGSUGOTOKU_NONE) {
      popularOdds.push("-");
    } else {
      let targetNumber = popularNumber.find(val => val.horseNum === n + 1);
      popularOdds.push(targetNumber.popular);
    }
  }
  return 0;
};

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(2);

//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 1) {
        sortType.value = 2;
        horses.sort(sort_by("HorseNum", true, parseInt));
      } else {
        sortType.value = 1;
        horses.sort(sort_by("HorseNum", false, parseInt));
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_my") {
      if (sortType.value === 3) {
        sortType.value = 4;
        horses.sort(sort_by("SelectedNum", false, parseInt));
      } else {
        sortType.value = 3;
        horses.sort(sort_by("SelectedNum", true, parseInt));
      }
      activeSortId.value = "sort_my";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
        horses.sort(sort_by("Odds", true, parseInt));
      } else {
        sortType.value = 5;
        horses.sort(sort_by("Odds", false, parseInt));
      }
      activeSortId.value = "sort_odds";
    } else if (id === "sort_weight") {
      if (sortType.value === 7) {
        sortType.value = 8;
        horses.sort(sort_by("HorseWeight", true, parseInt));
      } else {
        sortType.value = 7;
        horses.sort(sort_by("HorseWeight", false, parseInt));
      }
      activeSortId.value = "sort_weight";
    } else if (id === "sort_newspapermark") {
      if (sortType.value === 9) {
        sortType.value = 10;
        horses.sort(sort_by("DoubleCircleCount", false, parseInt));
      } else {
        sortType.value = 9;
        horses.sort(sort_by("DoubleCircleCount", true, parseInt));
      }
      activeSortId.value = "sort_newspapermark";
    } else if (id === "sort_horse") {
      if (sortType.value === 11) {
        sortType.value = 12;
        horses.sort(sort_by("HorseName", true, parseInt));
      } else {
        sortType.value = 11;
        horses.sort(sort_by("HorseName", false, parseInt));
      }
      activeSortId.value = "sort_horse";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_num") {
      sortType.value = 2;
      horses.sort(sort_by("HorseNum", true, parseInt));
      activeSortId.value = "sort_num";
    } else if (id === "sort_my") {
      sortType.value = 4;
      horses.sort(sort_by("SelectedNum", false, parseInt));
      activeSortId.value = "sort_my";
    } else if (id === "sort_odds") {
      sortType.value = 6;
      horses.sort(sort_by("Odds", true, parseInt));
      activeSortId.value = "sort_odds";
    } else if (id === "sort_weight") {
      sortType.value = 8;
      horses.sort(sort_by("HorseWeight", true, parseInt));
      activeSortId.value = "sort_weight";
    } else if (id === "sort_newspapermark") {
      sortType.value = 10;
      horses.sort(sort_by("DoubleCircleCount", false, parseInt));
      activeSortId.value = "sort_newspapermark";
    } else if (id === "sort_horse") {
      sortType.value = 12;
      horses.sort(sort_by("HorseName", true, parseInt));
      activeSortId.value = "sort_horse";
    }
  }
};

//ソート
let sort_by = function (field, reverse, primer) {
  reverse = reverse ? -1 : 1;
  const oddsSort = field === "Odds"
  return function (a, b) {
    if (field === "HorseName") {
      if (reverse === -1) {
        return a[field].localeCompare(b[field], 'ja');
      } else {
        return b[field].localeCompare(a[field], 'ja');
      }
    }
    a = a[field] * 10;
    b = b[field] * 10;
    if (field === "SelectedNum") {
      a = exchangeNum(a);
      b = exchangeNum(b);
    }
    if (typeof primer != "undefined") {
      a = oddsSort && (a <= 0 || isNaN(a)) ? 9999 * reverse * -1 : primer(a);
      b = oddsSort && (b <= 0 || isNaN(b)) ? 9999 * reverse * -1 : primer(b);
    }
    if (a > b) return reverse * -1;
    if (a < b) return reverse * 1;
  };
};

let exchangeNum = (num) => {
  switch (num) {
    case 10:
      return 50;
    case 20:
      return 40;
    case 30:
      return 30;
    case 40:
      return 20;
    case 50:
      return 10;
    default:
      return 0;
  }
};

const getRaceTitle = (run) => {
  if (Number(run.DOR) < 20030101) {  
    if (isCardTypeSimple()) {
      return
    } else if (isCardTypeSimple()) {
      return run.RaceTitle.slice(0, 6);
    } else if (isCardTypeNormal() || isCardTypeDetailed()) {
      return run.RaceTitle.slice(0, 10);
    } else {
      return run.RaceTitle
    }
  }
  //表示レース名を設定
  if (isCardTypeExtraSimple()) {
    return
  } else  if (isCardTypeSimple()) {
    return Number(run.RacetrackCd) <= 10 ? run.RaceTitle6 : run.RaceTitle.slice(0, 6);
  } else if (isCardTypeNormal() || isCardTypeDetailed()) {
    return Number(run.RacetrackCd) <= 10 ? run.RaceTitle10 : run.RaceTitle.slice(0, 10);
  } else if (run.RacetrackCd === '33') {
    return run.RaceTitle.slice(0, 4);
  } else {
    return run.RaceTitle
  }
}

const getRaceDOR = (raceDOR) => {
  //日付の整形
  if(isCardTypeNormal() || isCardTypeDetailed()) {
    return String(raceDOR).substring(2, 4) +
        "/" +
        String(raceDOR).substring(4, 6) +
        "/" +
        String(raceDOR).substring(6, 8);
  } else {
    return String(raceDOR).substring(4, 6) +
        "/" +
        String(raceDOR).substring(6, 8);
  }
}

//騎乗経験の取得
const getJockeyHorseRidingType = (horse) => {
  const jockeyRidingArray = horse?.RaceResultData?.JockeyRiding
  if (!jockeyRidingArray?.length) return '';
  const jockeyCdArray = jockeyRidingArray.map((jockey) => jockey.JockeyCd);
  const jockeyNameArray = jockeyRidingArray.map((jockey) => jockey.JockeyName.replace(/[ 　]/g, ''));
  const jockeyCd = isNar() === 1 ? horse.JockeyCd.slice(1) : horse.JockeyCd
  const jockeyName = horse.JockeyName.replace(/[ 　]/g, '')

  if (!jockeyCdArray?.includes(jockeyCd) && !jockeyNameArray?.includes(jockeyName)) {
    return '[初]';
  }

  if (jockeyCdArray[0] === jockeyCd || jockeyNameArray[0] === jockeyName) {
    return '[継]';
  }

  return '[替]';
}

/**
 * 出馬表カスタマイズタイプ取得
 */
const getCustomizeCardType = async () =>  {
  const request = HOST + PATH +  "Member/getCustomizeCardType.js" + BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(getUserId());

  await getJSON(request , true, function (status, json) {
    if(json.customizeCardType) customizeCardType.value = json.customizeCardType
  });
}

/**
 * 出馬表カスタマイズ表示判定
 * @returns {*|boolean}
 */
const isCardTypeNormal = () => {
  return customizeCardType.value === '1'
}
const isCardTypeDetailed = () => {
  return customizeCardType.value === '5'
}
const isCardTypeSimple = () => {
   return customizeCardType.value === '10'
}
const isCardTypeExtraSimple = () => {
   return customizeCardType.value === '11'
}

</script>

<template>
  <main class="layout-main">
    <RaceHeader />
    <template v-if="isLoaded">
      <section class="layout-section">
        <div class="container-racelist">
          <table
            class="table-grid -racedetail"
            :class="
              (mCourseVal === PAYINGNONE && !isWebview) || mCourseVal === PAYINGSUGOTOKU_NONE
                ? 'is-nologin'
                : ''
            "
          >
            <thead>
              <tr>
                <th v-if="isKakutei" class="number">
                  馬番
                  <i
                    class="filter-sort"
                    :class="
                      sortType === 1 ? '-desc' : sortType === 2 ? '-asc' : ''
                    "
                    @click="changeSort('sort_num')"
                  ></i>
                </th>
                <th v-else class="number">
                  <router-link v-if="[PAYINGNONE, PAYINGECONOMY].includes(mCourseVal)"
                     :to="mCourseVal === PAYINGNONE ? '/member/' : '/member/join_economy'">
                    優先順<br>
                    <i class="icon-premium"></i>
                  </router-link>
                  <span v-else>
                    優先順
                  </span>
                </th>
                <th class="mystamp">
                  印
                  <i
                    class="filter-sort"
                    :class="
                      sortType === 3 ? '-desc' : sortType === 4 ? '-asc' : ''
                    "
                    @click="changeSort('sort_my')"
                  >
                  </i>
                </th>
                <th class="odds">
                  オッズ
                  <i
                    class="filter-sort"
                    :class="
                      sortType === 5 ? '-desc' : sortType === 6 ? '-asc' : ''
                    "
                    @click="changeSort('sort_odds')"
                  ></i>
                </th>
                <th v-if="shouldShowMarks" class="marks">新聞印<br>◎
                  <i
                      class="filter-sort"
                      :class="
                      sortType === 9 ? '-desc' : sortType === 10 ? '-asc' : ''
                      "
                      @click="changeSort('sort_newspapermark')"
                  ></i>
                </th>
                <th class="horse">
                  馬名
                  <i class="filter-sort"
                    :class="
                      sortType === 11 ? '-desc' : sortType === 12 ? '-asc' : ''
                    "
                    @click="changeSort('sort_horse')">
                  </i>
                </th>
                <th class="barei">馬齢脚質</th>
                <th class="weight">
                  馬体重<br />レース間隔<i
                    class="filter-sort"
                    :class="
                      sortType === 7 ? '-desc' : sortType === 8 ? '-asc' : ''
                    "
                    @click="changeSort('sort_weight')"
                  ></i>
                </th>
                <th class="jockey">騎手</th>
                <th class="trainer">調教師<br />{{ raceTrackCdVal !== "33" ? '馬主' : '' }}</th>
                <th class="run">前走</th>
                <th class="run">2走前</th>
                <th class="run">3走前</th>
                <th class="run">4走前</th>
                <th class="run">5走前</th>
              </tr>
            </thead>
            <tbody v-if="horses">
              <tr
                v-for="h in horses"
                :key="h.horseNum"
                :class="[
                  h.AbnormalDiv === '1' ? '-cancel' : '',
                  h.AbnormalDiv === '3' ? '-cancel' : '',
                ]"
              >
              <td v-if="isKakutei"
              :class="[
                h.BracketNum < 10
                ? `umaban bgcolor-waku0${h.BracketNum}`
                : `umaban bgcolor-waku0${h.BracketNum}`,
              ]"
                >
                <i v-if="h.ExistHorseNum">{{ h.HorseNum }}</i>
              </td>
              <td v-else class="umaban">
                <i v-if="h.SpecialRaceOrder && ![PAYINGNONE, PAYINGECONOMY].includes(mCourseVal)">
                  {{ h.SpecialRaceOrder }}
                </i>
              </td>
                <td class="mystamp">
                  <div v-if="stamp[h.PedigreeNum] && h.PedigreeNum && h.PedigreeNum !== '0000000000'">
                    <span
                      :class="stampClass(h.PedigreeNum)"
                      @click="openModal($event, h.PedigreeNum)"
                    ></span>
                    <MyStampModal
                      @closeModal="closeStampModal($event, h.PedigreeNum)"
                      v-if="stamp[h.PedigreeNum].status === true"
                      :top="top"
                      :left="left"
                      :stampNum="h.HorseNum - 1"
                      :selectStamp="stamp[h.PedigreeNum].selectedNum"
                    />
                  </div>
                </td>
                <td v-if="h.AbnormalDiv === '1' || h.AbnormalDiv === '3'" class="odds">
                  <div>
                    <div
                        class="txt-odds"
                        :class="oddsClass(h.Odds)"
                        v-if="h.Odds"
                    >
                      {{ h.Odds == 0.0 ? '---.-' : h.Odds }}倍
                    </div>
                  </div>
                </td>
                <td v-else class="odds">
                  <router-link v-if="h.OddsLink && h.Odds && h.Odds != 0.0" :to="h.OddsLink">
                    <div
                      class="txt-odds"
                      :class="oddsClass(h.Odds)"
                      v-if="h.Odds"
                    >
                      {{ h.Odds }}倍
                    </div>
                    <div class="txt-odds -popular" v-if="h.Odds">
                      ({{ popularOdds[h.HorseNum - 1] }}人気)
                    </div>
                    <i class="icon-race -odds"></i>
                  </router-link>
                  <div v-else-if="h.Odds == 0.0">
                    <div
                        class="txt-odds"
                        :class="oddsClass(h.Odds)"
                        v-if="h.Odds"
                    >
                      ---.-倍
                    </div>
                    <div class="txt-odds -popular">
                      (-人気)
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="txt-odds"
                      :class="oddsClass(h.Odds)"
                      v-if="h.Odds"
                    >
                      {{ h.Odds }}倍
                    </div>
                    <div class="txt-odds -popular" v-if="h.Odds">
                      ({{ popularOdds[h.HorseNum - 1] }}人気)
                    </div>
                  </div>
                </td>
                <td class="marks" :class="h.newspaperMarkBgClass"
                    v-if="shouldShowMarks">
                  {{ h.DoubleCircleCount }}
                </td>
                <td class="horse">
                  <div class="inner">
                    <div class="info">
                      <div class="caption">{{ h.FatherName }}</div>
                      <router-link v-if="h.HorseNameUrl && h.IsExistRacehorseMaster"
                         :to="h.HorseNameUrl"
                         class="horsename"
                         :class="[h.FavClass,h.NextTargetClass]"
                         >
                        {{ h.HorseName }}
                        <i v-if="h.CastrationFlg === '1'" class="icon-race">去勢明け</i>
                        <i v-if="h.DowngradeFlg === 1" class="icon-status -degradation"></i>
                      </router-link>
                      <span v-else>{{ h.HorseName }}</span>
                      <div class="caption">
                        {{ h.MotherName }}{{ h.Grandfather }}
                      </div>
                    </div>
                    <div class="mark">
                      <div class="upper">
                        <i v-if="h.FavClassJockey" class="icon-mark -jockey"></i>
                        <i v-if="h.FavClassTrainer" class="icon-mark -trainer"></i>
                        <i v-if="h.FavClass" class="icon-mark -horse"></i>
                      </div>
                      <div class="lower">
                        <i v-if="h.NextTargetClass" class="icon-mark -nextrun"></i>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="barei">
                  {{ h.HairColorCd }}<br />
                  <ul class="icon-runstyle" :class="h.isMulti ? '-multi' : ''">
                    <li>
                      <span class="-nige" v-if="h.RunStyle.isNige">逃</span>
                    </li>
                    <li>
                      <span class="-senkou" v-if="h.RunStyle.isSenko">先</span>
                    </li>
                    <li>
                      <span class="-sasi" v-if="h.RunStyle.isSasi">差</span>
                    </li>
                    <li>
                      <span class="-oikomi" v-if="h.RunStyle.isOiko">追</span>
                    </li>
                  </ul>
                  <i
                    v-if="h.BlinkersDiv === '1' && h.BlinkersFirstFlg === '0'"
                    class="icon-blinker"
                  ></i>
                  <i
                    v-if="h.BlinkersDiv === '1' && h.BlinkersFirstFlg === '1'"
                    class="icon-blinkerbiginner"
                  ></i>
                </td>
                <td class="weight">
                  <div v-if="h.AbnormalDiv === '1'"></div>
                  <div v-else-if="h.AbnormalDiv === '3' || !h.PedigreeNum"
                       class="-showgraph linkpointer">
                    <div>
                      <div v-if="h.HorseWeight">
                        {{ h.HorseWeight }}
                        <span v-if="typeof h.WeightDifference === 'number'">
                          （
                          <span :style="h.HorseWeightClass">
                            {{ h.WeightDifferenceClass }}{{ h.WeightDifference }}
                          </span>
                          ）
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else
                    class="kg -showgraph linkpointer"
                    @click="
                      weightModal(
                        h.PedigreeNum,
                        h.WeightArray[0].DOR,
                        h.HorseWeight,
                        h.HorseName,
                        h.WeightArray,
                        h.WeightDifference
                      )
                    "
                  >
                    <div class="kg">
                      <div v-if="h.HorseWeight">
                        {{ h.HorseWeight }}
                        <span v-if="typeof h.WeightDifference === 'number'">
                          （
                          <span :style="h.HorseWeightClass">
                            {{ h.WeightDifferenceClass }}{{ h.WeightDifference }}
                          </span>
                          ）
                        </span>
                      </div>
                    </div>
                    <i class="icon-race -weight"></i>
                  </div>
                  {{ h.Interval }}
                </td>
                <td class="jockey" :class="{'-jra': NARFLAG === 0}">
                  <div class="inner">
                    <span class="text">
                      <span v-if="isCardTypeDetailed() && h.JockeyName && raceTrackCdVal !== '33'">
                        <span>
                          {{ getJockeyHorseRidingType(h) }}
                        </span>
                      </span>
                      <router-link v-if="h.IsExistJockeyMaster" :to="h.JockeyNameUrl">{{ h.JockeyName }}</router-link>
                      <span v-else>{{ h.JockeyName }}</span>
                      <span class="w-mark">{{ h.JockeyTrainingCd }}</span>
                      <br/><span class="w-number">{{
                        raceTrackCdVal === "33" ? Math.floor(h.Weight) : h.Weight
                      }}</span>
                      <span v-if="isCardTypeDetailed() && h.JockeyName && raceTrackCdVal !== '33'">
                        ({{
                          h.JockeyWins != "" && h.JockeyWins != undefined
                              ? h.JockeyWins
                              : 0
                        }}勝)
                      </span>
                    </span>
  
                    <span
                      v-if="h.JockeyImg"
                      class="silks"
                    >
                      <img
                        :src="h.JockeyImg"
                        alt=""
                        @error="changeImg(h)"
                      />
                    </span>
                  </div>
                </td>
                <td class="trainer-owner">
                  <router-link v-if="h.IsExistTrainerMaster" :to="h.TrainerNameUrl">{{
                    h.TrainerName
                  }}</router-link>
                  <span v-else>{{ h.TrainerName }}</span
                  >{{ h.EastWestDiv }}
                  <br />
                  <router-link
                    v-if="h.RaceResultData"
                    :to="
                      '/data/owner?HorseOwnerCd=' +
                      h.RaceResultData.HorseOwnerCd
                    "
                  >
                    {{ h.RaceResultData.HorseOwnerName }}
                  </router-link>
                </td>
                <template v-if="h.RaceResultData">
                  <template v-for="run in h.RaceResultData.RaceEntry">
                    <td v-if="run.IntervalMonth" class="run">
                      <p class="txt-rest">{{ run.IntervalMonth }}ヶ月休養</p>
                    </td>
                    <td v-else-if="run.DOR" :class="run.BgClass">
                      <div class="previousrun">
                        <div class="heading">
                          <span :class="run.RankClass">
                            {{ run.RacePlace }}
                          </span>
                          <div class="detail">
                            <div class="info">
                              {{ getRaceDOR(run.DOR) }} {{ run.Racetrack }}
                              <template v-if="isCardTypeDetailed() && raceTrackCdVal !== '33'">
                                {{ run.Weather + ' ' }}
                              </template>
                              <template v-if="!isCardTypeExtraSimple()">
                                {{ run.RaceEntryNum ? run.RaceEntryNum + '頭' : '' }} {{ run.HorseNum ? run.HorseNum + '番 ' : ' ' }}
                              </template>
                              <template v-if="isCardTypeNormal() || isCardTypeDetailed()">
                                {{ run.WinOdds ? ` ${run.WinOdds}人気 ` : ' ' }}
                              </template>
                              <template v-if="isCardTypeDetailed() && raceTrackCdVal !== '33'">
                                {{ run.RaceResultWinOdds ? run.RaceResultWinOdds + '倍' : '' }}
                              </template>
                            </div>
                            <div class="info">
                              <a v-if="run.RaceURL && Number(run.DOR) > 20030101" :href="run.RaceURL" class="card">
                                {{ getRaceTitle(run) }}
                                <i :class="run.GradeIcon" v-if="run.GradeIcon && raceTrackCdVal !== '33'">{{
                                    run.GradeCd !== "0" ? "" : run.RaceRank
                                  }}</i>
                                <template v-if="raceTrackCdVal !== '33'">
                                  <div v-if="isCardTypeExtraSimple() && run.RaceIcon"
                                      v-html="run.RaceIcon">
                                  </div>
                                </template>
                              </a>
                              <span v-else class="">
                                {{ getRaceTitle(run) }}
                                <i :class="run.GradeIcon" v-if="run.GradeIcon && raceTrackCdVal !== '33'">{{
                                  run.GradeCd !== "0" ? "" : run.RaceRank
                                }}</i>
                                <template v-if="raceTrackCdVal !== '33'">
                                  <div v-if="(isCardTypeNormal() || isCardTypeDetailed()) && run.RaceIcon"
                                      v-html="run.RaceIcon">
                                  </div>
                                </template>
                              </span>
                              <span v-if="isCardTypeDetailed() && raceTrackCdVal !== '33'" class="weightType">
                                {{ run.WeightTypeCd }}
                              </span>
                              <span v-if="raceTrackCdVal !== '33'" class="place">
                                {{ run.Track }}<template v-if="isCardTypeDetailed()">{{ run.TrackRotation }}</template>{{ run.Distance }}
                              </span>
                              <span v-else-if="isCardTypeSimple() || isCardTypeExtraSimple()" class="place">
                                ダ200
                              </span>
                              <span v-if="raceTrackCdVal === '33' && run.MoistureContent && isCardTypeNormal()">
                                {{ run.MoistureContent && run.MoistureContent !== '0' ? '(' + run.MoistureContent + '%)' : '' }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="raceTrackCdVal !== '33'" class="info">
                          <span class="passing">
                            {{ run.CorneringOrder }}
                          </span>
                          <span class="jockey">
                              {{ run.JockeyName }}
                          </span>
                          <span v-if="isCardTypeDetailed()"
                                class="jwins">
                            ({{ run.JockeyWins }}勝)
                          </span>
                          <span v-if="isCardTypeNormal() || isCardTypeDetailed()"
                                class="jweight">
                              {{ run.BurdenWeight }}
                          </span>
                          <span v-if="!isCardTypeExtraSimple()"
                                class="hweight">
                              {{ run.Weight }}
                          </span>
                        </div>
                        <!-- ばんえいの場合 -->
                        <div v-else class="info">
                          <span style="margin-left: 27px;">
                              {{ run.JockeyName }}
                          </span>
                          <span v-if="!isCardTypeExtraSimple()"
                                class="jweight">
                              {{ Math.floor(run.BurdenWeight) }}
                          </span>
                          <span v-if="!isCardTypeExtraSimple()"
                                class="hweight">
                              {{ run.Weight }}
                          </span>
                        </div>
                        <div class="info">
                          <span v-if="raceTrackCdVal !== '33'" class="time">
                            <i
                                v-if="run.BlinkersDiv === '1' && run.BlinkersFirstFlg === '0'"
                                class="icon-blinker">
                            </i>
                            <i
                                v-if="run.BlinkersDiv === '1' && run.BlinkersFirstFlg === '1'"
                                class="icon-blinkerbiginner">
                            </i>
                            <span v-if="(isCardTypeNormal() || isCardTypeDetailed()) && run.RecordDiv !== 'R' && run.Pace"
                                  :class="{
                                'text-pace -slow': run.Pace === 'S',
                                'text-pace -middle': run.Pace === 'M',
                                'text-pace -high': run.Pace === 'H',
                              }">
                              {{ run.Pace + " " }}
                            </span>
                            <i class="icon-record" v-if="run.RecordDiv === 'R'">R</i>
                            <span class="total" v-if="run.PrizeRaceTime">
                              {{ run.PrizeRaceTime }}
                            </span>
                            <span v-if="run.FurlongTime">
                              （<i class="txt-rank" :class="run.FurlongClass">
                                {{ run.FurlongTime }}
                              </i>）
                            </span>
                            <!-- 高さ確保用 -->
                            {{ run.PrizeRaceTime || run.FurlongTime ? '' : '　'}}
                          </span>
                          <span v-else class="">
                            <span class="" v-if="run.PrizeRaceTime" style="margin-left: 27px; margin-right: 0.5em; font-weight: 700;">
                              {{ run.PrizeRaceTime }}
                            </span>
                            <!-- 高さ確保用 -->
                            {{ run.PrizeRaceTime || run.FurlongTime ? '' : '　'}}
                          </span>
                          <span class="horse" v-if="!isCardTypeExtraSimple()">
                            {{ run.Top2HorseName }}
                            <template v-if="(isCardTypeNormal() || isCardTypeDetailed()) && run.TimeGapWithTop2">
                            （{{run.TimeGapWithTop2 }}）
                            </template>
                            <template v-if="isCardTypeDetailed()">
                              {{run.RunStyle1to3 }}
                            </template>
                          </span>
                        </div>
                      </div>
                    </td>
                  </template>
                  <td v-for="i in 5 - h.RaceResultData.RaceEntry.length"></td>
                </template>
                <template v-if="!h.RaceResultData">
                  <td v-for="i in 5"></td>
                </template>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th v-if="isKakutei" class="number">馬番</th>
                <th v-else class="number">優先順</th>
                <th class="mystamp">印</th>
                <th class="odds">オッズ</th>
                <th v-if="shouldShowMarks" class="marks">新聞印<br />◎</th>
                <th class="horse">馬名</th>
                <th class="barei">馬齢脚質</th>
                <th class="weight">馬体重<br />レース間隔</th>
                <th class="jockey">騎手</th>
                <th class="trainer">調教師<br />馬主</th>
                <th class="run">前走</th>
                <th class="run">2走前</th>
                <th class="run">3走前</th>
                <th class="run">4走前</th>
                <th class="run">5走前</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。<br>
        <template v-if="NARFLAG === 0">
          ※ハンデ競走の斤量は通常開催終了翌日の16時頃に発表となり表示されます。<br>
          ※ハンデ競走では、ハンデの重い順に3頭が優先されます。<br>
          ※一部G1では特別登録日翌日発表のレーティング順位の上位5頭が優先されます。
        </template>
        <template v-if="hasNotice">
          <br>
          ※フルゲート以上の登録馬がいる場合は5頭が無作為抽出されるので、優先順位どおり出走馬が決まらない場合があります。
        </template>
      </p>
      <section class="layout-section">
          <div class="inner">
            <div class="nav-center -nomargin">
              <router-link to="/race/help" class="btn-basic -guide">出馬表の見方</router-link>
            </div>
          </div>
        </section>
      <section class="layout-section">
        <div class="inner" v-if="predictedSalesBanner" v-html="predictedSalesBanner"></div>
      </section>
    </template>
  </main>

  <WeightModal
    v-if="modalOpen === true"
    v-on:closeDialog="modalOpen = false"
    :modalOpen="modalOpen"
    :targetWeightData="targetWeightData"
  ></WeightModal>
</template>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
