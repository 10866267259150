<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">馬券コレクション</h1>
    </section>
    <section class="layout-section">
      <div class="inner">
        <router-link
          :to="{ path: '/balance/', query: { year: year, month: month } }"
          class="btn-basic -wide"
        >
          収支管理画面から馬券コレクションを新規作成
        </router-link>
      </div>
      <nav class="nav-filter">
        <ul>
          <li :class="{ '-current': currentTab === 0 }" @click="changeTab(0)">
            <span class="label">全て</span>
          </li>
          <li :class="{ '-current': currentTab === 1 }">
            <span class="label" @click="changeTab(1)">的中のみ</span>
          </li>
          <li :class="{ '-current': currentTab === 2 }">
            <span class="label" @click="changeTab(2)">万馬券のみ</span>
          </li>
        </ul>
      </nav>
      <table class="table-grid -collection -fit">
        <tr v-for="collectionData in showCollectionDatas" :key="collectionData">
          <th v-if="collectionData.skip">
            {{ collectionData.DOR }}
          </th>
          <td v-else>
            <router-link
              :to="{
                path: '/collection/card',
                query: { id: collectionData.IDKey },
              }"
              class="link-cell"
            >
              <span class="caption">
                {{ collectionData.Racetrack }}{{ collectionData.RaceNum }}R/{{
                  collectionData.TOB
                }}
              </span>
              <div class="title">
                {{ collectionData.RaceName }}
                <span :class="innerGradeClass(collectionData.GradeCd)"></span>
              </div>
              <span v-show="collectionData.HitFlg" class="icon-hit">的中</span>
            </router-link>
          </td>
        </tr>
      </table>
      <div
        v-if="
          showCollectionDatas.length - skipCount - showLimit >= 0 &&
          currentLength > showLimit
        "
        class="nav-group"
      >
        <span class="btn-basic" @click="isMore"> もっと見る </span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import {
  PAYINGSUGOTOKU_NONE,
  PAYINGNONE,
  retrieveCourse,
  isNar,
  getUserId,
} from "../../assets/js/common";
import {HOST, PATH, BACKWORDPATH, JOINPATH} from "../../assets/js/define";

export default {
  data() {
    return {
      year: "",
      month: "",
      currentTab: 0,
      collectionDatas: [],
      showLimit: 30,
      skipCount: 0,
      currentLength: 0,
      userId: "",
    };
  },
  computed: {
    showCollectionDatas() {
      const list = [];
      let count = 0;
      const self = this;
      this.collectionDatas.forEach(function (value) {
        if (value && count < self.showLimit) {
          list.push(value);
          if (!value.skip) {
            count++;
          }
        }
      });
      this.skipCount = list.length - count;
      return list;
    },
  },
  mounted() {
    let self = this;
    this.callRetrieveCourse(function (courseId, showMarks, userId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.userId = userId || getUserId();
        //収支管理画面から馬券コレクションを新規作成ボタンリンク生成
        self.setButtonLink();
      }
    });
  },
  methods: {
    callRetrieveCourse (callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false, '');
      }
    },
    isMore() {
      this.showLimit += 5;
    },
    setButtonLink() {
      const date = new Date();
      this.year = date.getFullYear();
      this.month = ("0" + (date.getMonth() + 1)).slice(-2);
      this.getCollection(0);
    },
    getCollection(narrowingCondition) {
      if (this.userId == null || this.userId === "") {
        document.body.className = "status-loaded";
        return;
      }
      document.body.className = "";
      const req =
        HOST +
        PATH +
        "TicketCollection/bettingTicketCollectionListAPI.js" +
        BACKWORDPATH +
        "?userId=" +
          encodeURIComponent(this.userId) +
        "&narrowingCondition=" +
        narrowingCondition +
        "&narFlag=" +
        isNar();
      this.getCollectionData(req, true);
    },
    changeTab(tabNum) {
      this.currentTab = tabNum;
      this.getCollection(tabNum);
    },
    getCollectionData(request, messageFlag) {
      let self = this;
      if (request) {
        axios
          .get(request, { "Content-Type": "application/json", timeout: 120000 })
          .then((response) => {
            if (response.data.length) {
              let collectionDatasTmp = {};
              collectionDatasTmp = Object.assign(
                collectionDatasTmp,
                response.data[0].SearchResultList
              );
              // タブ切り替え時の初期化
              self.collectionDatas = [];
              collectionDatasTmp = Object.keys(collectionDatasTmp).map(function(key) {
                return collectionDatasTmp[key];
              }).sort(function(a, b) {
                return b.DOR - a.DOR
              });
              let dateFlg = "";
              collectionDatasTmp.forEach(function (value, key) {
                if (dateFlg !== value.DOR) {
                  dateFlg = value.DOR;
                  self.collectionDatas.push({
                    DOR: self.date2StringTypeYmdd(value.DOR),
                    skip: true,
                  });
                }
                collectionDatasTmp[key].RaceNum =
                  collectionDatasTmp[key].RaceNum.charAt(0) === "0"
                    ? collectionDatasTmp[key].RaceNum.substring(1)
                    : collectionDatasTmp[key].RaceNum;
                self.collectionDatas.push(collectionDatasTmp[key]);
              });
              self.currentLength = collectionDatasTmp.length;
            }
            document.body.className = "status-loaded";
          })
          .catch((e) => {
            document.body.className = "status-loaded";
          });
      }
    },
    innerGradeClass(gradeCd) {
      let gradeClass = "icon-race";
      const nar = isNar() === 1;
      if (gradeCd === "A") {
        gradeClass += nar ? " -jpn1" : " -g1";
      } else if (gradeCd === "B") {
        gradeClass += nar ? " -jpn2" : " -g2";
      } else if (gradeCd === "C") {
        gradeClass += nar ? " -jpn3" : " -g3";
      } else if (gradeCd === "D") {
        gradeClass += " -largeprize";
      } else if (gradeCd === "L") {
        gradeClass += " -L";
      } else gradeClass = "";
      return gradeClass;
    },
    date2StringTypeYmdd(dateStr) {
      let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
      let month = parseInt(dateStr.slice(4, 6), 10);
      let date = parseInt(dateStr.slice(6, 8), 10);
      let mDate = new Date(parseInt(dateStr.slice(0, 4), 10), month - 1, date);
      let day = DAY_LBL[mDate.getDay()];

      return (
        dateStr.slice(0, 4) + "年" + month + "月" + date + "日(" + day + ")"
      );
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/collection/style.scss" scoped></style>
