<template>
  <main class="layout-main">
    <section class="layout-section">
      <div class="nav-round">
        <div class="inner -separate">
          <div class="-dayplace">
            <div class="form-select">
              <select v-model="year" @change="getRaceCalendar(year, month)">
                <option
                  v-for="(year, index) in years"
                  :key="index"
                  :value="year"
                >
                  {{ year }}年
                </option>
              </select>
            </div>
          </div>

          <div class="-race">
            <a
              v-if="isPrevMonth"
              @click="minusMonth(month), getRaceCalendar(year, month)"
              class="btn-arrow -prev"
            ></a>
            <div class="form-select">
              <select v-model="month" @change="getRaceCalendar(year, month)">
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month"
                >
                  {{ month }}月
                </option>
              </select>
            </div>
            <a
              v-if="isNextMonth"
              @click="plusMonth(month), getRaceCalendar(year, month)"
              class="btn-arrow -next"
            ></a>
          </div>
        </div>
      </div>
      <table
        v-if="isData && loadedPage"
        class="table-grid -racecalendar"
        :class="NARFLAG === 0 ? '-JRA' : ''"
      >
        <tbody>
          <template v-if="NARFLAG === 1">
            <!-- NARカレンダー -->
            <tr
              :class="{
                '-today': dayComp.isToday,
              }"
              v-for="(dayComp, index) in Calendar"
              :key="index"
            >
              <th
                class="date"
                :class="{
                  '-sat': dayComp.days === '土',
                  '-sun': dayComp.days === '日',
                }"
                v-if="dayComp.day || ''"
              >
                {{ dayComp.day }}({{ dayComp.days }})
              </th>
              <td v-if="dayComp.day || ''">
                <template v-for="(race, index) in dayComp.races" :key="index">
                  <router-link
                    class="icon-place"
                    v-if="race.url !== null"
                    :to="race.url"
                  >
                    <div class="inner">
                      <div class="place">
                        {{ race.place
                        }}<i
                          v-if="race.isNighter === 1"
                          class="icon-time -night"
                        ></i>
                      </div>

                      <i v-if="race.isIPAT === 1" class="icon-ipat">IPAT</i>
                      <template v-if="dayComp.isToday && isUnfinished(race.StartTime)">
                        <span class="r">{{ race.R }}R</span>
                        <time class="date">{{ race.StartTime }}</time>
                      </template>
                    </div>
                  </router-link>
                  <div v-else class="icon-place">
                    <div class="inner">
                      <div class="place">
                        {{ race.place
                        }}<i
                          v-if="race.isNighter === 1"
                          class="icon-time -night"
                        ></i>
                      </div>

                      <i v-if="race.isIPAT === 1" class="icon-ipat">IPAT</i>
                      <span v-if="dayComp.isToday" class="r"
                        >{{ race.R }}R</span
                      >
                      <time v-if="dayComp.isToday" class="date">{{
                        race.StartTime
                      }}</time>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <!-- JRAカレンダー -->
            <template v-for="(dayComp, index) in Calendar">
              <tr
                :class="{
                  '-today': dayComp.isToday,
                }"
                v-if="dayComp.races.length > 0"
                :key="index"
              >
                <th class="date" v-if="dayComp.day || ''">
                  {{ month }}/{{ dayComp.day }}(<span
                    :class="{
                      _sat: dayComp.days === '土',
                      _sun: dayComp.days === '日',
                    }"
                    >{{ dayComp.days }}</span
                  >)
                </th>
                <td v-if="dayComp.day || ''">
                  <ul class="grid-calendar">
                    <li v-for="(race, index) in dayComp.races" :key="index">
                      <div
                        v-if="
                          Object.keys(race).length > 0 &&
                          Object.keys(race.jraData).length > 0
                        "
                        class="card-cal"
                        :class="race.jraData.Status === '2' ? '-cancel' : ''"
                      >
                        <div class="racelist">
                          <router-link class="link" :to="race.jraData.raceListURL"
                            >{{ Number(race.jraData.meeting) }}{{ race.place
                            }}{{ Number(race.jraData.titleDay) }}</router-link
                          >
                        </div>
                        <div class="race">
                          <router-link class="link" v-if="race.url" :to="race.url"
                            ><span class="label">{{
                              race.jraData.raceTitle
                            }}</span>
                            <i
                              :class="
                                race.jraData.gradeCd === undefined ||
                                race.jraData.gradeCd === ''
                                  ? race.jraData.regulationCd != ''
                                    ? 'icon-race ' + race.jraData.regulationCd
                                    : ''
                                  : 'icon-race ' + race.jraData.gradeCd
                              "
                              >{{
                                race.jraData.regulationCd != ""
                                  ? race.jraData.regulationCd
                                  : ""
                              }}</i
                            >
                          </router-link>
                          <span class="link" v-else
                            ><span class="label">{{
                              race.jraData.raceTitle
                            }}</span
                            ><i
                              :class="
                                race.jraData.gradeCd === undefined ||
                                race.jraData.gradeCd === ''
                                  ? race.jraData.regulationCd != ''
                                    ? 'icon-race ' + race.jraData.regulationCd
                                    : ''
                                  : 'icon-race ' + race.jraData.gradeCd
                              "
                              >{{
                                race.jraData.regulationCd != ""
                                  ? race.jraData.regulationCd
                                  : ""
                              }}</i
                            ></span
                          >
                        </div>
                        <div class="trend">
                          <router-link
                            class="link"
                            v-if="race.jraData.trendURL"
                            :to="race.jraData.trendURL"
                            >レース傾向</router-link
                          >
                          <span class="link" v-else>レース傾向</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-if="dayComp.races[0].jraData" class="caption">
                    {{ dayComp.races[0].jraData.koyomi }}
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <div v-else-if="loadedPage" class="h2group">
        <div class="flexitem1"><h2>レース情報がありません。</h2></div>
      </div>
    </section>
    <section v-if="NARFLAG === 0" class="layout-section">
      <div class="inner">
        <div class="nav-bool -nomargin">
          <router-link to="/race/list" class="btn-basic -wide">レース一覧</router-link>
        </div>
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  getJSON,
  getUrlVars,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  RACEPATH,
  BACKWORDPATH,
} from "../../assets/js/define";
import moment from "moment";

export default {
  data() {
    return {
      Calendar: [],
      months: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      years: [],
      year: "",
      month: "",
      isData: false,
      isNextMonth: false,
      isPrevMonth: false,
      loadedPage: false,
      NARFLAG: 0,
    };
  },
  mounted() {
    let self = this;
    self.NARFLAG = isNar();

    // パラメータ取得
    let params = getUrlVars();
    let year = moment().format('YYYY');
    let month = moment().format('MM');

    if (typeof params.DOR !== "undefined") {
      year = params.DOR.slice(0, 4);
      month =
          params.DOR.slice(4, 5) === "0"
              ? params.DOR.slice(5, 6)
              : params.DOR.slice(4, 6);
    }
    self.year = Number(year);
    self.month = Number(month);

    self.getRaceCalendar(self.year, self.month);
  },
  methods: {
    getRaceCalendar(paramYear, paramMonth) {
      let self = this;
      self.Calendar = [];
      self.isData = false;
      self.loadedPage = false;
      let request =
        HOST +
        PATH +
        "RaceInfo/raceCalendar.js" +
        BACKWORDPATH +
        "?year=" +
        paramYear +
        "&month=" +
        paramMonth +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      getJSON(request, true, function (status, json) {
        if (json != undefined && json.RaceList.length > 0) {
          try {
            const keibajyou = {
              "01": "札幌",
              "02": "函館",
              "03": "福島",
              "04": "新潟",
              "05": "東京",
              "06": "中山",
              "07": "中京",
              "08": "京都",
              "09": "阪神",
              10: "小倉",
              30: "門別",
              31: "北見",
              32: "岩見",
              33: "帯広",
              34: "旭川",
              35: "盛岡",
              36: "水沢",
              37: "上山",
              38: "三条",
              39: "足利",
              40: "宇都",
              41: "高崎",
              42: "浦和",
              43: "船橋",
              44: "大井",
              45: "川崎",
              46: "金沢",
              47: "笠松",
              48: "名古屋",
              49: "紀三",
              50: "園田",
              51: "姫路",
              52: "益田",
              53: "福山",
              54: "高知",
              55: "佐賀",
              56: "荒尾",
              57: "中津",
              58: "札幌",
              59: "函館",
              60: "新潟",
              61: "中京",
            };
            let placeArray = {};

            //JRAのみ レース配置場所の設定
            if (self.NARFLAG === 0) {
              let isEast = new Object();
              for (let i = 0; i < json.RaceList.length; i++) {
                if (isEast[json.RaceList[i].DOR] == undefined) {
                  isEast[json.RaceList[i].DOR] = false;
                }
                if (
                  !isEast[json.RaceList[i].DOR] &&
                  (json.RaceList[i].RacetrackCd == "05" ||
                    json.RaceList[i].RacetrackCd == "06")
                ) {
                  isEast[json.RaceList[i].DOR] = true;
                }
              }

              //JSONを日付と場所でソートする
              json.RaceList.sort(function (a, b) {
                let da = a["DOR"];
                let db = b["DOR"];
                if (da < db) return -1;
                if (da > db) return 1;

                a = a["RacetrackCd"];
                b = b["RacetrackCd"];

                let aconv = a;
                let bconv = b;

                if (isEast[da]) {
                  //札幌：01→21、函館：02→22、福島：03→13、新潟：04→14、中京：07→17、小倉：10→20
                  if (a == "01") aconv = "21";
                  if (a == "02") aconv = "22";
                  if (a == "03") aconv = "13";
                  if (a == "04") aconv = "14";
                  if (a == "07") aconv = "17";
                  if (a == "10") aconv = "20";

                  if (b == "01") bconv = "21";
                  if (b == "02") bconv = "22";
                  if (b == "03") bconv = "13";
                  if (b == "04") bconv = "14";
                  if (b == "07") bconv = "17";
                  if (b == "10") bconv = "20";
                } else {
                  //札幌：01→21、函館：02→22、中京：07→17、小倉：10→20
                  if (a == "01") aconv = "21";
                  if (a == "02") aconv = "22";
                  if (a == "07") aconv = "17";
                  if (a == "10") aconv = "20";

                  if (b == "01") bconv = "21";
                  if (b == "02") bconv = "22";
                  if (b == "07") bconv = "17";
                  if (b == "10") bconv = "20";
                }

                if (aconv < bconv) return -1;
                if (aconv > bconv) return 1;
                return 0;
              });

              let setArea = function (dor, areaCd) {
                if (!(dor in placeArray)) {
                  placeArray[dor] = new Array();
                }
                placeArray[dor].push(areaCd);
              };

              for (let i = 0; i < json.RaceList.length; i++) {
                setArea(json.RaceList[i].DOR, json.RaceList[i].RacetrackCd);
              }
              for (let i in placeArray) {
                if (placeArray[i].length < 3) {
                  //その日の開催場が3未満の場合、東/西/ローカルまたはローカル/ローカルになるようにブランクをいれる
                  let newPlaces = new Array();
                  let places = placeArray[i];

                  let eastPlace = new Array();
                  let westPlace = new Array();
                  let localPlace = new Array();
                  let isSort = false;
                  for (let j = 0; j < places.length; j++) {
                    if (places[j] == "05" || places[j] == "06") {
                      eastPlace.push(places[j]);
                    } else if (places[j] == "08" || places[j] == "09") {
                      westPlace.push(places[j]);
                    } else {
                      localPlace.push(places[j]);
                      //福島：03、新潟：04が存在するか
                      if (
                        !isEast[i] &&
                        (places[j] == "03" || places[j] == "04")
                      ) {
                        isSort = true;
                        // 21361
                        if (i == "20171103") {
                          isSort = false;
                        }
                        if (i == "20190429") {
                          isSort = false;
                        }
                      }
                    }
                  }
                  if (
                    localPlace.length == 2 ||
                    (eastPlace.length == 1 && westPlace.length == 1) ||
                    isSort
                  ) {
                    //左詰めで問題なし、現状のソートのまま
                    continue;
                  }

                  if (eastPlace.length == 0) {
                    //東の開催が無いのでブランク
                    newPlaces.push("");
                  } else {
                    for (let l = 0; l < eastPlace.length; l++) {
                      newPlaces.push(eastPlace[l]);
                    }
                  }
                  if (westPlace.length == 0) {
                    //西の開催が無いのでブランク
                    newPlaces.push("");
                  } else {
                    for (let l = 0; l < westPlace.length; l++) {
                      newPlaces.push(westPlace[l]);
                    }
                  }
                  for (let l = 0; l < localPlace.length; l++) {
                    newPlaces.push(localPlace[l]);
                  }

                  placeArray[i] = newPlaces;
                }
              }
            }

            let Calendar = []
            for (let i = 1; i <= 31; i++) {
              const year = String(paramYear)
              const month = (Number(paramMonth) < 10 ? '0' + paramMonth : paramMonth)
              const day = (i < 10 ? '0' + i : String(i))
              const dor = year + month + day
              const data = json.RaceList.filter(val => val.DOR === dor)
              if (data.length === 0) continue
              const races = []
              for (let j in data) {
                const useData = data[j]
                const placeText = keibajyou[useData.RacetrackCd];
                const racetrackCd = useData.RacetrackCd;
                let isNighter = 0;
                let isIPAT = 0;
                const jraData = {};
                //NAR・JRAそれぞれで扱うデータを抽出
                if (self.NARFLAG === 1) {
                  //NARデータ
                  isNighter = useData.isNighter;
                  isIPAT = useData.isIPAT;
                } else {
                  //JRAデータ
                  //レース配置格納
                  //各レースタイトル部分
                  let meeting = "";
                  let titleDay = "";
                  if ("RaceMeeting" in useData && useData.RaceMeeting != null) {
                    meeting = useData.RaceMeeting;
                  }
                  if ("RaceDay" in useData && useData.RaceDay != null) {
                    titleDay = useData.RaceDay;
                  }

                  //グレードアイコン設定
                  let icon = "";
                  let regulation_txt = "";
                  if ("GradeCd" in useData && useData.GradeCd != null) {
                    if (/^A$/i.test(useData.GradeCd)) {
                      icon = useData.TrackTypeCd === "3" ? "-jg1" : "-g1"
                    } else if (/^B$/i.test(useData.GradeCd)) {
                      icon = useData.TrackTypeCd === "3" ? "-jg2" : "-g2"
                    } else if (/^C$/i.test(useData.GradeCd)) {
                      icon = useData.TrackTypeCd === "3" ? "-jg3" : "-g3"
                    } else if (/^D$/i.test(useData.GradeCd)) {
                      icon = "-largeprize";
                    } else if (/^L$/i.test(useData.GradeCd)) {
                      icon = "-L";
                    } else if (
                        "RaceShortName6" in useData &&
                        useData.RaceShortName6 !== ""
                    ) {
                      //重賞でない特別競走には競争条件をアイコン表示（その他重賞時には競争条件をアイコン表示）
                      if (useData.DOR.slice(0, 6) <= 201905) {
                        if (useData.RaceRegulationCd == "500万円以下") {
                          //500万下
                          regulation_txt = "500";
                        } else if (
                            useData.RaceRegulationCd === "1000万円以下"
                        ) {
                          //1000万下
                          regulation_txt = "1000";
                        } else if (
                            useData.RaceRegulationCd === "1600万円以下"
                        ) {
                          //1600万下
                          regulation_txt = "1600";
                        } else if (useData.RaceRegulationCd === "オープン") {
                          //オープン
                          icon = useData.TrackTypeCd === "3" ? "-jop" : "-op";
                        }
                      } else {
                        if (useData.RaceRegulationCd === "500万円以下") {
                          //500万下
                          regulation_txt = "1勝";
                        } else if (
                            useData.RaceRegulationCd === "1000万円以下"
                        ) {
                          //1000万下
                          regulation_txt = "2勝";
                        } else if (
                            useData.RaceRegulationCd === "1600万円以下"
                        ) {
                          //1600万下
                          regulation_txt = "3勝";
                        } else if (useData.RaceRegulationCd === "オープン") {
                          //オープン
                          icon = useData.TrackTypeCd === "3" ? "-jop" : "-op";
                        }
                      }
                    }
                  }

                  let obj = {
                    meeting: meeting,
                    titleDay: titleDay,
                    raceListURL: "/race" + "?DOR=" + dor + "&RacetrackCd=" + useData.RacetrackCd,
                    trendURL:
                        useData.AllCancel === 0 && useData.isResult === 1
                            ? "/info/trends" +
                            "?DOR=" +
                            dor +
                            "&RacetrackCd=" +
                            useData.RacetrackCd +
                            "&RaceMeeting=" +
                            useData.RaceMeeting +
                            "&RaceDay=" +
                            useData.RaceDay
                            : "",
                    raceTitle: useData.RaceShortName6,
                    gradeCd: icon,
                    regulationCd: regulation_txt,
                    trackTypeCd: useData.TrackTypeCd,
                    AllCancel: useData.AllCancel,
                    isResult: useData.isResult,
                    Status: useData.Status,
                    koyomi: useData.koyomi,
                  };
                  Object.assign(jraData, obj);
                }
                const StartTime = useData.StartTime;
                const R = useData.RaceNum;
                let url = null;
                if ("isLinkMode" in useData && useData.isLinkMode != null) {
                  if (useData.isLinkMode == 2) {
                    //レース情報へリンク
                    url = isNar() === 1 ?
                        "/race" +
                        "?DOR=" +
                        dor +
                        "&RacetrackCd=" +
                        useData.RacetrackCd :
                        "/race/card" +
                        "?DOR=" +
                        dor +
                        "&RacetrackCd=" +
                        useData.RacetrackCd +
                        "&RaceNum=" +
                        useData.RaceNum
                  } else if (useData.isLinkMode == 1) {
                    //レース結果へリンク
                    url =
                        "/" +
                        RACEPATH +
                        "results?DOR=" +
                        dor +
                        "&RacetrackCd=" +
                        useData.RacetrackCd +
                        "&RaceNum=" +
                        useData.RaceNum;
                  }
                }

                const race = {
                  place: placeText,
                  isNighter: isNighter,
                  racetrackCd: racetrackCd,
                  isIPAT: isIPAT,
                  StartTime: StartTime,
                  R: R,
                  url: url,
                  jraData: jraData,
                };
                if (isNar() === 0 && placeArray[dor][races.length] !== useData.RacetrackCd) {
                  races.push("");
                }
                races.push(race);
              }
              const myWeek = moment(`${year}-${month}-${day}`).day();
              Calendar.push({
                day: i,
                races: races,
                days: ["日", "月", "火", "水", "木", "金", "土"][myWeek],
                isToday: dor === moment().format('YYYYMMDD'),
              })
            }
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
              51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              Calendar.forEach((value, index) => {
                return value.races.sort(function(x, y) {
                  return sortArray.indexOf(Number(x.racetrackCd)) - sortArray.indexOf(Number(y.racetrackCd));
                })
              })
            }
            self.Calendar = Calendar;
            const karaRaces = Calendar.filter((dayComp) => {
              return dayComp.day || "";
            });
            self.isData = karaRaces.length > 0
          } catch (e) {
            console.log("予期せぬエラーが発生しました。");
          }
        }
        if (json.NewestDOR != undefined && json.NewestDOR >= 6) {
          const maxYear = parseInt(json.NewestDOR.slice(0, 4), 10);
          const maxMonth = parseInt(json.NewestDOR.slice(4, 6), 10);
          const minYear = isNar() ? 2020 : 2003;
          const years = []

          for (let i = minYear; i <= maxYear; i++) {
            years.unshift(i);
          }
          self.years = years

          if (isNar() === 1) {
            if (self.year === maxYear && self.month === maxMonth) {
              self.isNextMonth = false;
              self.isPrevMonth = true;
            } else if (self.year === maxYear && self.month === maxMonth + 1) {
              self.isNextMonth = false;
              self.isPrevMonth = true;
            } else if (self.year >= maxYear && self.month > maxMonth) {
              self.isNextMonth = false;
              self.isPrevMonth = false;
            } else if (self.year === minYear && self.month === 1) {
              self.isNextMonth = true;
              self.isPrevMonth = false;
            } else {
              self.isNextMonth = true;
              self.isPrevMonth = true;
            }
          }
        }
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    },
    plusMonth(m) {
      let self = this;
      if (m === 0) {
        m = 12;
        self.month = 12;
        self.year = self.year - 1;
      } else if (m === 12) {
        m = 1;
        self.month = 1;
        self.year = self.year + 1;
      } else {
        self.month = m + 1;
      }
    },
    minusMonth(m) {
      let self = this;
      if (m === 1) {
        m = 12;
        self.month = 12;
        self.year = self.year - 1;
      } else if (m === 13) {
        m = 1;
        self.month = 1;
        self.year = self.year + 1;
      } else {
        self.month = m - 1;
      }
    },
    isUnfinished(startTime) {
      if (startTime.length !== 5) return;

      const formatTime = startTime.substr(0, 2) + startTime.substr(3, 2);
      const nowTime = moment().format('HHmm');

      return formatTime > nowTime;
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
