<script setup>
import {ref, reactive, onMounted, watch, onServerPrefetch} from "vue";
import {
  getJSON,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  setCookie,
  DATETYPE,
  getUserAgent,
  getUserId,
  isAndroid,
  getTopBanner,
  isAtag,
  getGradeIconClass,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  JRA_WP_HOST,
} from "../../assets/js/define";
import {
  showLastFixedResultRaceInfo,
  loadTodayRaceInfoVer2,
  loadNewsHeadline,
  innerRaceSymbol,
  getRelatedMovies,
  shouldShowFavoriteButton,
} from "../../functions/top/common";
import moment from "moment";
import {setData} from "../../functions/topInit";
import Slider from "./Slider.vue";
import GridMovie from "./movie/GridMovieWeekDay.vue";

let slider = reactive([]);
let advertisement = reactive([]);
let announce = reactive([]);
let importantrace = reactive([]);
let campaign = reactive([]);
let information = reactive([]);
let localPrize = reactive([]);
const banner = ref("");
const freeArea = ref("");
const isWebView = ref(false);
const isSugotokuAndroidWebView = ref(false);
const isAndroidWebView = ref(false);
const isIphoneWebView = ref(false);
const isSugotoku = ref(false);
const isLogin = ref(true);
let mUserId = "";
import {useStoreTopWeekday} from "../../stores/top/weekday-states";
import {fetch as fetchStoreTopWeekday} from "../../functions/top/weekday";
import {storeToRefs} from "pinia";
const storeTopWeekday = useStoreTopWeekday();
const {
  wpContents,
  userCourse,
} = storeToRefs(storeTopWeekday);

onServerPrefetch(async () => {
  await fetchStoreTopWeekday();
  await loadWp(1);
  loadedPage.value = true;
});

onMounted(async () => {
  isWebView.value = getUserAgent();
  isSugotokuAndroidWebView.value = isAndroid();
  if(Object.keys(wpContents.value || {}).length === 0) {
    await fetchStoreTopWeekday();
  }

  const request =
      HOST +
      PATH +
      "getLoginInfo.js" +
      BACKWORDPATH +
      "/?NarFlag=0";
  getJSON(request, false, async (status, json) => {
    setData(status, json);
    //ユーザコースをセット
    let courseId = json.courseIdStr;
    mUserId = json.userId || getUserId();
    isLogin.value = !!mUserId;
    isIphoneWebView.value = json.isIphone;
    isAndroidWebView.value = json.isAndroid;

    if (courseId === PAYINGNONE) {
      userCourse.value = 1;
    } else if (courseId === PAYINGECONOMY) {
      userCourse.value = 2;
    } else if (courseId === PAYINGSUGOTOKU) {
      userCourse.value = 3;
      isSugotoku.value = true
    } else if ([PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12].includes(courseId)) {
      userCourse.value = 4;
    }

    if (isWebView.value && json.paymentMethod === null && json.openId !== null && json.contentId !== null) {
      let userType
      if (json.openId.includes('https://') || json.contentId.includes('dm1e100500020')) {
        if (json.courseId == 7) {
          userType = 6
        } else if (json.courseId == 1) {
          userType = 4
        } else {
          userType = 5
        }
      } else {
        if (json.courseId == 7) {
          userType = 9
        } else if (json.courseId == 1) {
          userType = 7
        } else {
          userType = 8
        }
      }

      callDart(mUserId, userType)
    }

    if (courseId !== PAYINGNONE) {
      loadFav();
    }
    loadRace();
    if(Object.keys(wpContents.value)?.length) {
      await fetchStoreTopWeekday();
    }
    loadWp(userCourse.value);
    //banner
    if (!isWebView.value) {
      banner.value = await getTopBanner(isAndroidWebView.value, isIphoneWebView.value)
    }
  });
});

const callDart = (userId, userType) => {
  try {
    window.flutter_inappwebview.callHandler(
      "complete_cert_logon",
      {
        "user_id": userId,
        "user_type": userType
      }
    );

    return
  } catch (err) {
    console.log(err)
    return
  }
}

const loadFav = () => {
  //今週のレース情報を取得
  const request =
    HOST +
    PATH +
    "MyFavorite/getMyFavorite.js" +
    BACKWORDPATH +
    "?userID=" +
      encodeURIComponent(mUserId) +
    "&narFlag=0"
  getJSON(request, true, function (status, json) {});
};

const loadWp = async (course) => {
  const courseKey = {
    2: 'member-300',
    4: 'member-1000',
    1: 'member-none',
    3: 'member-sugotoku-app',
  }[course] ?? 1;

  //advertisement
  wpContents.value.advertisement?.forEach(element => {
    element.member[courseKey] ? advertisement.push(element) : '';
  });
  //announce
  wpContents.value.announce?.forEach(element => element.url = "/announce/?id=" + element.ID);
  wpContents.value.announce?.forEach(element => {
    element.member[courseKey] ? announce.push(element) : '';
  });
  //importantrace
  wpContents.value.importantrace?.forEach((element) => importantrace.push(element));
  //campaign
  wpContents.value.campaign?.forEach(element => {
    element.member[courseKey] ? campaign.push(element) : '';
  });
  //information
  wpContents.value.information?.forEach(element => element.url = "/information?id=" + element.ID);
  wpContents.value.information?.forEach(element => {
    element.member[courseKey] ? information.push(element) : '';
  });
  //local_exchange_prize
  Object.assign(localPrize, wpContents.value.local_exchange_prize ?? {});
  //free_area
  freeArea.value = isSugotoku.value
                    ? wpContents.value.free_area?.sugotoku_app
                    : wpContents.value.free_area?.basic
}

const shouldShowFavButton = ref(false)
let raceInfo = reactive({});
let kakutei = reactive({});
let newsHeadlineData = reactive({});
let lastWeekRaceData = reactive([]);
let nextWeekG1Data = reactive({});
let todayRace = reactive({});
let loadedPage = ref(false);
let dateType = ref('0');
let relatedMovies = reactive({});

let strDate = ref("");
const loadRace = async () => {
  document.body.className = "";
  let params;

  let mDate = new Date();
  strDate.value =
    mDate.getFullYear() +
    ("0" + (mDate.getMonth() + 1)).slice(-2) +
    ("0" + mDate.getDate()).slice(-2);
  params = { DOR: strDate.value };

  if (userCourse.value !== 1) {
    shouldShowFavoriteButton(shouldShowFavButton, mUserId);
  }

  //APIコールstep1
  let req =
    HOST +
    PATH +
    "Top/getTopInfo.js" +
    BACKWORDPATH +
    "?dor=" +
    params.DOR +
    "&narFlag=0"

  await getJSON(req, true, function (status, jsonall) {
    if (status == SUCCESS) {
      Object.assign(raceInfo, jsonall);
      let json = jsonall.results1;
      let json2 = jsonall.results2;
      let json3 = jsonall.results3;
      if (Object.keys(json).length > 0) {
        let gtri = json.getTodayRaceInfo;
        dateType.value = gtri.DateType;
        setCookie(DATETYPE, gtri.DateType); //曜日情報

        //レース非開催の場合
        //当日表示レース情報読み込み
        Object.assign(
          todayRace,
          loadTodayRaceInfoVer2(
            params.DOR,
            gtri,
            json2,
            json3,
            json2.getChangeInfo
          )
        );

        //先週の開催結果読み込み
        Object.assign(lastWeekRaceData, json2.getLastWeekPatternRacesResult);

        // 次週開催G1情報読み込み
        Object.assign(nextWeekG1Data, json2.getNextWeekG1RacesInfo);

        //ニュース情報読み込み
        const newsHeadline = loadNewsHeadline(json.getNewsHeadline);
        Object.assign(newsHeadlineData, newsHeadline);

        displayRaceSlider(json.getTodayRaceInfo.PatternRaces)
      }
    }
  });

  // 関連レース動画取得
  try {
    let mainRaces = {};
    if(Array.isArray(todayRace.raceList)) {
      todayRace.raceList.forEach(list => {
        list.races.forEach(r => {
          mainRaces[r.mainRace.SpecialRaceNum ?? "0000"] = r.mainRace.PedigreeNum.split("_").filter(n => n);
        });
      });
    }
    Object.assign(relatedMovies, await getRelatedMovies(mainRaces));
  } catch (e) {
    console.log(e);
  }

  // 最終確定表示処理
  const lastFixedResultRaceInfo = await showLastFixedResultRaceInfo();
  Object.assign(kakutei, lastFixedResultRaceInfo);

  document.body.className = "status-loaded";
  loadedPage.value = true;
};

//トップページのスライド画像の描画関数
const displayRaceSlider = function (mRaceArray) {
  //スライド表示順をセットg1->g2->g3順
  const g1Races = [
    ...mRaceArray.filter(v => 'G1' === v.Grade),
    ...mRaceArray.filter(v => 'G2' === v.Grade),
    ...mRaceArray.filter(v => 'G3' === v.Grade)
  ]

  //描画
  for (let i = 0; i < g1Races.length; i++) {
    const grade = g1Races[i].Grade.toLowerCase();
    const linkUrl = `/race/card?DOR=${g1Races[i].DOR}&RacetrackCd=${g1Races[i].RacetrackCd}&RaceNum=${g1Races[i].RaceNum}`
    const imageUrl = `${HOST}shared/img/database/race/${g1Races[i].RaceImgURL}`
    const raceLabel = `${getDisplayDateStrForMain(g1Races[i].DOR, 1)} ${g1Races[i].Racetrack}${g1Races[i].RaceNum}R`
    const gradeIcon = `icon-race -${grade}`
    const html = `
    <a href="${linkUrl}" class="item -jra -${grade}">
      <div class="image">
        <img src="${imageUrl}" alt="race-bannar">
      </div>
      <div class="raceInfo">
        <span class="name">${g1Races[i].RaceMainName}</span>
        <span class="detail">${raceLabel}<i class="${gradeIcon}"></i></span>
      </div>
    </a>
`
    slider.push(html)
  }
}

//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd, type) => {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(yyyymmdd.slice(4, 6), 10);
  let date = parseInt(yyyymmdd.slice(6, 8), 10);
  let mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  return type > 0 ?
      month + "/" + date + "(" + day + ")" :
      month + "月" + date + "日" + "(" + day + ")";
};

const getGradeCd = (mainRace) => {
  return getGradeIconClass(mainRace.GradeCd, mainRace.TrackTypeCd, mainRace.RaceRegulationCd);
}

const getGradeCdForLastWeek = (mainRace) => {
  let gradeIcon = ""
  // JRA競走場
  if (mainRace.Grade === "G1") {
    gradeIcon = mainRace.TrackType === "障害" ?
        "icon-race -jg1" : "icon-race -g1"
  } else if (mainRace.Grade === "G2") {
    gradeIcon = mainRace.TrackType === "障害" ?
        "icon-race -jg2" : "icon-race -g2"
  } else if (mainRace.Grade === "G3") {
    gradeIcon = mainRace.TrackType === "障害" ?
        "icon-race -jg3" : "icon-race -g3"
  } else if (mainRace.Grade === "その他の重賞") {
    gradeIcon = "icon-race -largeprize"
  }
  return gradeIcon;
}
</script>
<template>
  <main class="layout-main">
    <div class="welcome" v-if="!isLogin && !isWebView">
      こちらから <router-link to="/login">ログイン</router-link> /
      <router-link to="/member">会員登録</router-link><span> してください</span>
    </div>
    <div v-if="banner" v-html="banner"></div>

    <section class="layout-section">
      <slider v-if="loadedPage" :slider="slider"></slider>

      <div class="container-banner" v-if="Object.keys(advertisement).length">
        <div
          v-for="item in advertisement"
          :class="item.size"
          class="link"
        >
          <a
              v-if="item.url"
              :href="item.url"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                class="-contain"
                :src="item.img"
                v-bind:alt="item.title"
            />
          </a>
          <router-link
              v-else
              :to="'/advertisement/?id=' + item.ID"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                class="-contain"
                :src="item.img"
                v-bind:alt="item.title"
            />
          </router-link>
        </div>
      </div>

      <div
        class="container-wrapper -pad"
        v-if="announce.length > 0 || shouldShowFavButton"
      >
        <ul class="container-report" v-if="announce.length > 0">
          <li v-for="item in announce">
            <a class="link-cell" v-if="item.link_url && isAtag(item.link_url)" :href="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </a>
            <router-link v-else-if="item.link_url" class="link-cell" :to="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </router-link>
            <router-link class="link-cell" v-else :to="item.url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD H:mm") }}</time>
            </router-link>
          </li>
        </ul>
        <router-link
          v-if="shouldShowFavButton"
          to="/fav/race"
          class="btn-basic -wide -center -imporntat"
          >MYお気に入り/次走狙い 出走予定</router-link
        >
      </div>
    </section>

    <section v-if="'raceList' in todayRace" class="layout-section">
      <div class="layout-sectionheader -race">
        <h1 class="heading-section">レース情報</h1>
      </div>
      <div class="container-box">
        <div
          v-for="(day, index) in todayRace.raceList"
          :key="index"
          class="inner"
        >
          <div class="layout-title -marginbottom">
            <h4 class="heading-circle" v-html="day.date"></h4>
            <div class="nav">
              <router-link :to="day.raceURL" class="btn-basic">その他のレース一覧</router-link>
            </div>
          </div>

          <div
            v-if="day.changeData.changeFlg != 0"
            class="container-changeinfo -white"
          >
            <div class="summary">
              <span>{{
                day.changeData.changeMsg != "" &&
                day.changeData.changeMsg != undefined
                  ? day.changeData.changeMsg
                  : "取消・変更等はございません（" +
                    day.changeData.changeTime.getHours() +
                    "時" +
                    day.changeData.changeTime.getMinutes() +
                    "分現在）"
              }}</span>
            </div>
            <div class="nav">
              <router-link :to="day.changeData.changeURL" class="btn-basic"
                >変更情報一覧</router-link
              >
            </div>
          </div>

          <div v-for="(race, idx) in day.races" :key="idx" class="container-racewithmovie">
            <div class="info">
              <div class="link-cell card-point">
                <div class="corner">
                  <router-link
                    class="label"
                    :to="
                    '/race?DOR=' +
                    day.DOR +
                    '&RacetrackCd=' +
                    race.mainRace.RacetrackCd
                  "
                  >{{ race.mainRace.racetrackCd
                    }}<i
                      v-if="'Weather' in race.condition"
                      :class="'icon-weather -w' + race.condition.Weather"
                    ></i
                    ></router-link>
                </div>
                <router-link class="summary" :to="'/race/card' + race.mainRaceLinks.mQuery">
                  <div class="inner">
                    <div class="race">
                      <h3 class="name">
                        {{ race.mainRace.RaceMainName }}
                        <i :class="getGradeCd(race.mainRace)"></i>
                      </h3>
                    </div>
                    <ul class="list-meta">
                      <li>{{ race.mainRace.StartTimeShow }}</li>
                      <li>
                        {{ race.mainRace.TrackType }}{{ race.mainRace.Distance }}m
                      </li>
                    </ul>
                  </div>
                </router-link>
              </div>
            </div>
            <GridMovie v-if="relatedMovies[race.mainRace.SpecialRaceNum]"
                       :races="Object.values(relatedMovies[race.mainRace.SpecialRaceNum]) ?? []"
                       :userCourse="userCourse"
                       :loginUrl="{ 1: '/member/', 2: '/member/join_economy' }[userCourse] ?? ''"
            />
          </div>
        </div>
        <div v-if="Object.keys(nextWeekG1Data).length > 0" class="inner">
          <h2 class="heading-circle">次週開催G1情報</h2>
          <div
            v-for="(nextData, index) in nextWeekG1Data"
            :key="index"
            class="link-cell card-point"
          >
            <div class="image">
              <div class="thumb">
                <img
                  :src="
                    nextData.RaceImgURL != ''
                      ? '/shared/img/database/race/' + nextData.RaceImgURL
                      : '/assets/images/common/blank_horse.png'
                  "
                  alt=""
                />
              </div>
            </div>
            <router-link
              class="summary"
              :to="
                '/race/card?DOR=' +
                nextData.DOR +
                '&RacetrackCd=' +
                nextData.RacetrackCd +
                '&RaceNum=' +
                nextData.RaceNum
              "
            >
              <div class="inner">
                <div class="race">
                  <h3 class="name">
                    {{ nextData.RaceName }}
                    <i class="icon-race"
                       :class="nextData.TrackType === '障' ? '-jg1' : '-g1'">
                    </i>
                  </h3>
                  <span v-if="'FullGate' in nextData" class="sub"
                    >フルゲート{{ nextData.FullGate }}頭</span
                  >
                </div>
                <ul class="list-meta">
                  <li>
                    <span v-html="getDisplayDateStrForMain(nextData.DOR)"></span>
                    <span>{{ ' ' + nextData.Racetrack + ' ' }}</span>
                    <span v-if="nextData.StartTime">
                      {{ nextData.StartTime.slice(0, 2) }}:{{
                        nextData.StartTime.slice(2, 4)
                      }}
                    </span>
                  </li>
                  <li>{{ nextData.TrackType }}{{ nextData.Distance }}m</li>
                  <li v-if="nextData.RaceSymbolCd != ''">
                    {{ innerRaceSymbol(nextData.RaceSymbolCd) }}
                  </li>
                </ul>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="lastWeekRaceData.length > 0 && dateType === '1'"
      class="layout-section"
    >
      <div class="layout-sectionheader -race -nomargin">
        <h1 class="heading-section">先週の開催結果</h1>
        <div class="nav">
          <router-link
            class="btn-basic"
            :to="'/race/list?DOR=' + lastWeekRaceData[0].DOR"
            >結果一覧</router-link
          >
        </div>
      </div>

      <div class="container-box">
        <div class="inner">
          <div class="layout-sub -min">
            <div
              v-for="(lastrace, index) in lastWeekRaceData"
              :key="index"
              class="link-cell card-point"
            >
              <router-link
                class="summary -oneColumn"
                :to="
                  '/race/results?DOR=' +
                  lastrace.DOR +
                  '&RacetrackCd=' +
                  lastrace.RacetrackCd +
                  '&RaceNum=' +
                  lastrace.RaceNum
                "
              >
                <div class="inner">
                  <div class="race">
                    <h3 class="name">
                      {{ lastrace.RaceName }}
                      <i :class="getGradeCdForLastWeek(lastrace)"></i>
                    </h3>
                  </div>
                  <ul class="list-meta">
                    <li v-html="getDisplayDateStrForMain(lastrace.DOR)"></li>
                    <li>{{ lastrace.Racetrack }}</li>
                    <li>{{ lastrace.TrackType }}{{ lastrace.Distance }}m</li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
          <div class="layout-narrow -padding">
            <router-link to="/race/importantrace" class="btn-basic -wide"
              >重賞カレンダー</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -news">
        <h1 class="heading-section">ニュース</h1>
        <div class="nav">
          <router-link class="btn-basic" to="/news/">ニュース一覧</router-link>
        </div>
      </div>

      <ul class="list-news">
        <li
          v-if="Object.keys(importantrace).length"
          class="item"
          v-for="race in importantrace"
        >
          <div class="summary">
            <a v-if="isAtag(race.url)" :href="race.url" class="title" v-html="race.title"></a>
            <router-link v-else :to="race.url" class="title" v-html="race.title"></router-link>
            <time class="caption"
              >{{ moment(race.date).format("YYYY.MM.DD HH:mm") }} 更新</time
            >
          </div>
          <div class="thumb">
            <img
              v-if="race.thumbnail"
              src="../../assets/images/common/blank_square.png"
              alt=""
              :style="{ 'background-image': 'url(' + race.thumbnail + ')' }"
            />
            <!-- デジマース側でno image画像作成する可能性あるためコメントアウトで対応(小林) -->
            <!-- <img
              v-else
              src="../../assets/images/common/blank_square.png"
              alt=""
              style="
                background-image: url(/assets/images/common/blank_winkeiba.png);
              "
            /> -->
          </div>
          <div class="important"><span class="icon-caution">注目!</span></div>
        </li>

        <!--以下通常NEWS-->
        <li
          v-if="Object.keys(newsHeadlineData).length"
          v-for="news in newsHeadlineData"
          class="item"
        >
          <div class="summary">
            <a v-if="isAtag(news.mHref)" :href="news.mHref" class="title">{{ news.Headline }}</a>
            <router-link v-else :to="news.mHref" class="title">{{ news.Headline }}</router-link>
            <time class="caption">{{ news.mUpdateTime }} 更新</time>
          </div>
          <div class="thumb">
            <img
              v-if="news.ImageFileURL"
              src="../../assets/images/common/blank_square.png"
              alt=""
              :style="{ backgroundImage: 'url(' + news.ImageFileURL + ')' }"
            />
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section" v-if="!isSugotoku">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">独占コラム</h1>
      </div>
      <router-link to="/column/sumii/">
        <div class="image-fit">
          <img src="/assets/images/column/sumii/column_banner_4.png" />
        </div>
      </router-link>
    </section>

    <section
      v-if="lastWeekRaceData.length > 0 && dateType === '2'"
      class="layout-section"
    >
      <div class="layout-sectionheader -race -nomargin">
        <h1 class="heading-section">先週の開催結果</h1>
        <div class="nav">
          <router-link
            class="btn-basic"
            :to="'/race/list?DOR=' + lastWeekRaceData[0].DOR"
            >結果一覧</router-link
          >
        </div>
      </div>

      <div class="container-box">
        <div class="inner">
          <div class="layout-sub -min">
            <div
              v-for="(lastrace, index) in lastWeekRaceData"
              :key="index"
              class="link-cell card-point"
            >
              <router-link
                class="summary -oneColumn"
                :to="
                  '/race/results?DOR=' +
                  lastrace.DOR +
                  '&RacetrackCd=' +
                  lastrace.RacetrackCd +
                  '&RaceNum=' +
                  lastrace.RaceNum
                "
              >
                <div class="inner">
                  <div class="race">
                    <h3 class="name">
                      {{ lastrace.RaceName }}
                      <i :class="getGradeCdForLastWeek(lastrace)"></i>
                    </h3>
                  </div>
                  <ul class="list-meta">
                    <li v-html="getDisplayDateStrForMain(lastrace.DOR)"></li>
                    <li>{{ lastrace.Racetrack }}</li>
                    <li>{{ lastrace.TrackType }}{{ lastrace.Distance }}m</li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
          <div class="layout-narrow -padding">
            <router-link to="/race/importantrace" class="btn-basic -wide"
              >重賞カレンダー</router-link
            >
          </div>
        </div>
      </div>
    </section>
    <section v-if="Object.keys(localPrize).length > 0" class="layout-section">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">地方交流重賞</h1>
      </div>
      <a :href="localPrize.url" class="link-cell card-point -padding">
        <div class="image">
          <div class="thumb">
            <img :src="localPrize.image ? localPrize.image.url : ''" alt="" />
          </div>
        </div>
        <div class="summary">
          <div class="inner">
            <div v-html="localPrize.title" class="race"></div>
            <ul v-html="localPrize.meta" class="list-meta"></ul>
          </div>
        </div>
      </a>
    </section>

    <section class="layout-section" v-if="Object.keys(campaign).length && !isSugotoku">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">キャンペーン</h1>
      </div>
      <div class="container-banner">
        <div
          v-for="item in campaign"
          :class="item.size"
          class="link"
        >
          <a
            v-if="item.url"
            :href="item.url"
            :target="item.window ? '_blank' : '_self'"
          >
            <img
              src="../../assets/images/index/blank_campaign.png"
              v-bind:alt="item.title"
              :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </a>
          <router-link
            v-else
            :to="'/campaign/?id=' + item.ID"
            :target="item.window ? '_blank' : '_self'"
          >
            <img
              src="../../assets/images/index/blank_campaign.png"
              v-bind:alt="item.title"
              :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </router-link>
        </div>
      </div>
    </section>

    <section class="layout-section" v-if="Object.keys(information).length">
      <div class="layout-sectionheader -information">
        <h1 class="heading-section">INFORMATION</h1>
      </div>
      <ul class="list-news">
        <li
          class="item"
          v-for="info in information"
        >
          <div class="summary">
            <a v-if="info.link_url && isAtag(info.link_url)" :href="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </a>
            <router-link v-else-if="info.link_url" :to="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
            <router-link v-else :to="info.url">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section" v-if="freeArea" v-html="freeArea"></section>

    <section class="layout-section" v-if="isSugotoku && isSugotokuAndroidWebView">
      <h2 class="heading-circle -padding -bordertop">サポート</h2>
      <ul class="list-line">
        <li class="item">
          <router-link to="/horselist/" class="link-cell">JRA登録馬一覧</router-link>
        </li>
        <li class="item">
          <a href="https://www.sugotoku.docomo.ne.jp/cs/faq.html" class="link-cell">よくある質問／お問い合わせ</a>
        </li>
        <li class="item">
          <a href="https://www.sugotoku.docomo.ne.jp/cs/representation.html" class="link-cell">提供事業者に関する表示</a>
        </li>
      </ul>
    </section>
    <section class="layout-section" v-else-if="isSugotoku && isWebView">
      <h2 class="heading-circle -padding -bordertop">サポート</h2>
      <ul class="list-line">
        <li class="item">
          <router-link to="/horselist/" class="link-cell">JRA登録馬一覧</router-link>
        </li>
      </ul>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style scoped>
.link-cell .inner {
  display: block;
}

a {
  cursor: pointer;
}

.welcome {
  background-color: #9fc97a;
  text-align: center;
  padding: 6px 0;
}
.welcome a {
  color:white;
  text-decoration:underline;
}
.welcome span {
  color:black;
}

</style>