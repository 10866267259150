<script setup>
import {
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import { useRoute, useRouter } from 'vue-router';
import {dayWeek, getJSON, SUCCESS} from "../../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../../assets/js/define";

const route = useRoute();
const router = useRouter();

import moment from "moment";

const selectedYear = ref("");
onMounted(() => {
  selectedYear.value = route.query["year"] ? route.query["year"] : moment().format('YYYY')
  router.replace({path: `${route.fullPath}`, query: {year: selectedYear.value}});
  getListData();
});

const onSelectYear = () => {
  router.push({path: `${route.fullPath}`, query: {'year': selectedYear.value}});
}

watch(route, (prev, next) => {
  if(prev.query['year']) {
    selectedYear.value = next.query['year'];
    getListData();
  }
})

let lists = reactive([]);
let years = reactive([]);

const getListData = () => {
  const request =
      HOST +
      RACEINFOPATH +
      "getGradedRaceCalendar.js" +
      BACKWORDPATH +
      "?year=" + selectedYear.value;
  document.body.className = "";
  getJSON(request, true, function (status, json) {
    lists.splice(0);
    years.splice(0);
    if (status === SUCCESS) {
      for (let row of json['lists']) {
        lists.push(row)
      }
      for (let row of json['years']) {
        years.unshift(row)
      }
    }
    document.body.className = "status-loaded";
  })
}
const getGrade = (track, grade) => {
  let result = ''
  if (grade === 'A') {
    result = track !== "3" ? '-g1' : '-jg1'
  } else if (grade === 'B') {
    result = track !== "3" ? '-g2' : '-jg2'
  } else if (grade === 'C') {
    result = track !== "3" ? '-g3' : '-jg3'
  } else if (grade === 'D') {
    result = '-largeprize'
  }
  return result;
}
const formatDate = (dor) => {
  const raceYear = dor.substring(0, 4);
  const raceMonth = dor.substring(4, 6);
  const raceDay = dor.substring(6, 8);
  return raceMonth + '/' + raceDay + '(' + dayWeek(raceYear, raceMonth, raceDay) + ')';
}
const getHorseLink = (pedigreeNum) => {
  return '/data/horse?PedigreeNum=' + pedigreeNum;
}
const getRaceLink = (row, type) => {
  return '/race/' + type + '?DOR=' + row.DOR + '&RacetrackCd=' + row.RacetrackCd + '&RaceNum=' + row.RaceNum;
}

</script>
<template>
  <main class="layout-main">
    <section class="layout-section">
      <div class="nav-round">
        <div class="inner -separate">
          <div class="-dayplace">
            <div class="form-select">
              <select v-model="selectedYear" @change="onSelectYear()">
                <option v-for="item in years" :key="item" :value="item">
                  {{ item }} 年
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <table class="table-grid -importantrace">
        <thead>
          <tr>
            <th class="mdcdr" rowspan="2">
              月/日<br /><span class="caption">競馬場:距離</span>
            </th>
            <th class="mdcdr" style="width: 75%;">レース名</th>
          </tr>
          <tr>
            <th class="wn">優勝馬</th>
          </tr>
        </thead>
        <tbody v-for="row in lists">
        <tr>
          <th class="race g3" rowspan="2">
            <span class="date" v-html="formatDate(row.DOR)"></span><br/>
            <span class="caption">{{ row.RacetrackName }}:{{ row.TrackTypeName }}{{ row.Distance }}m</span>
          </th>
          <td class="race">
            <router-link v-if="row.PedigreeNum" :to="getRaceLink(row, 'results')">
              {{ row.RaceShortName10 }}
            </router-link>
            <router-link v-else-if="row.ExistEntry" :to="getRaceLink(row, 'card')">
              {{ row.RaceShortName10 }}
            </router-link>
            <template v-else>{{ row.RaceShortName10 }} </template>
            <i class="icon-race" :class="getGrade(row.TrackTypeCd, row.GradeCd)" />
          </td>
        </tr>
        <tr>
          <td v-if="row.HorseName">
            <router-link :to="getHorseLink(row.PedigreeNum)">
              {{ row.HorseName }}
            </router-link>
          </td>
          <td v-else >-</td>
        </tr>
        </tbody>
      </table>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>
